import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { SuccessToaster } from "../src/app/components/toaster";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWiwQ1zXESmfkpUWlGDpYURs97OBR79To",
  authDomain: "fanoos-32b29.firebaseapp.com",
  projectId: "fanoos-32b29",
  storageBucket: "fanoos-32b29.appspot.com",
  messagingSenderId: "650533610392",
  appId: "1:650533610392:web:503f8abc9be67c104041ed",
  measurementId: "G-HKSPJ56GN2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getUserToken = async () => {
  return getToken(messaging, {
    vapidKey: "BBKJMilU3NtMJueZUOhdVuTSbQJ0CHf4zO8dZtZBb6ek8y6kzDaTQFRCtE9Ezh2OnNvCIA7yfolnOc3WVjo2NLI"
  }).then((currentToken) => {
    if (currentToken) {
      return currentToken;
    } else {
      console.log("no auth");
    }
  }).catch((err) => {
    console.log("err", err);
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      SuccessToaster("You have a new notification");
      resolve(payload);
    });
  });