const Colors = {
  primary: "#EC991C",
  secondary: "#EC1C1C",
  dark1: "#0A0302",
  dark2: "#4B4B4B",
  dark3: "#161616",
  inactive: "#221302",
  white: "#ffffff",
  black: "#000000",
  shadowColor: "#0A0A0A", //add B3 for transparency
  success: "#36D146", // add 19 for transparency
  warning: "#FFA300", // add 19 for transparency
  danger: "#CF1C1C", // add 19 for transparency
  dashboardGradient: "linear-gradient(180deg, rgba(236,153,28,1) 0%, rgba(236,28,28,1) 100%)",
  buttonGradient: "linear-gradient(90deg, rgba(236,153,28,1) 0%, rgba(236,28,28,1) 100%)"
}

export default Colors;