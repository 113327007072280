import Dashboard from "../../views/dashboard";
import CreateAdmin from "../../views/admin/createAdmin";
import AdminList from "../../views/admin/adminList";
import UpdateAdmin from "../../views/admin/updateAdmin";
import MembersList from "../../views/members/membersList";
import MembersRequest from "../../views/members/membersRequest";
import MemberDetail from "../../views/members/detailView";
import BusinessesList from "../../views/businesses/businessesList";
import BusinessesRequest from "../../views/businesses/businessesRequest";
import BusinessDetail from "../../views/businesses/detailView";
import OffersList from "../../views/offers/offersList";
import OffersRequest from "../../views/offers/offersRequest";
import InvitesList from "../../views/invites/invitesList";
import EventsList from "../../views/invites/eventsList";
import Notifications from "../../views/notification";
import Chat from "../../views/chat";
import Settings from "../../views/settings/categories";
import Upload from "../../views/settings/upload";
import BrandDetail from "../../views/businesses/brandDetailView";
import EventDetail from "../../views/invites/eventsList/eventDetail";
import InvitedMemberList from "../../views/invites/invitesList/invitedMemberList";
import OfferDetail from "../../views/offers/detailView";
import MembershipRequest from "../../views/businesses/membershipRequest";

const adminRoutes = [
  {
    path: "/",
    component: <Dashboard />
  },
  {
    path: "/create-admin",
    component: <CreateAdmin />
  },
  {
    path: "/admin-list",
    component: <AdminList />
  },
  {
    path: "/update-admin/:id",
    component: <UpdateAdmin />
  },
  {
    path: "/members-list",
    component: <MembersList />
  },
  {
    path: "/members-request",
    component: <MembersRequest />
  },
  {
    path: "/member-detail/:id",
    component: <MemberDetail />
  },
  {
    path: "/businesses-list",
    component: <BusinessesList />
  },
  {
    path: "/businesses-request",
    component: <BusinessesRequest />
  },
  {
    path: "/membership-request",
    component: <MembershipRequest />
  },
  {
    path: "/business-detail/:id",
    component: <BusinessDetail />
  },
  {
    path: "/brand-detail/:id",
    component: <BrandDetail />
  },
  {
    path: "/offers-list",
    component: <OffersList />
  },
  {
    path: "/offers-request",
    component: <OffersRequest />
  },
  {
    path: "/invites-list",
    component: <InvitesList />
  },
  {
    path: "/events-list",
    component: <EventsList />
  },
  {
    path: "/event-detail/:id",
    component: <EventDetail />
  },
  {
    path: "/notifications",
    component: <Notifications />
  },
  {
    path: "/chat",
    component: <Chat />
  },
  {
    path: "/settings",
    component: <Settings />
  },
  {
    path: "/upload-images",
    component: <Upload />
  },
  {
    path: "/invited-members-list",
    component: <InvitedMemberList />
  },
  {
    path: "/offer-detail/:id",
    component: <OfferDetail />
  },
];

export default adminRoutes;