import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Box, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import BreadCrumb from '../../../components/breadcrumb';
import Colors from '../../../style';
import { InfoIcon, InstagramIcon, SnapchatIcon, TiktokIcon, UserIcon, MarkerIcon, MarkerIcon2, ClockIcon2, FacebookIcon, ClockIcon, WorldWebIcon, TimezoneIcon } from '../../../assets/icons';
import { ArrowLeft, Close, CloudUpload, Edit, Language, MoreHoriz } from '@mui/icons-material';
import Images from '../../../assets/images';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AdminServices from '../../../api/admin/AdminServices';
import { SecondaryCard } from '../../../components/cards';
import { ErrorToaster, SuccessToaster } from '../../../components/toaster';
import { PageDot } from "../../../components/UI"
import { baseUrl } from '../../../../axios';
import { EditField } from '../../../components/inputfields';
import { useForm } from 'react-hook-form';
import { EditButton, SecondaryButton } from '../../../components/buttons';
import AddressForm from '../../../components/addressForm';
import { Capitalize } from '../../../utils/Capitalize';

function BrandDetail() {
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState(null)
  const [isActive, setIsActive] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isBranchEdit, setIsBranchEdit] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  const [updatedImages, setUpdatedImages] = useState([]);
  const { register, handleSubmit, setValue } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();
  const inputRef = useRef();
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const openMap = () => {
    const url = `https://www.google.com/maps?q=${selectedBranch?.latitude},${selectedBranch?.longitude}`;
    window.open(url, '_blank');
  }

  const handleStatusApproved = async () => {
    try {
      const obj = {
        user_id: state?.business?.user_id,
        is_approved: true,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    };
  };

  const handleStatusDisapproved = async () => {
    try {
      const obj = {
        user_id: state?.business?.user_id,
        is_approved: false,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    };
  };

  const handleStatus = async (e) => {
    const obj = {
      is_active: e.target.checked,
    }
    try {
      const result = await AdminServices.updateUser(state?.business?.user_id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message)
        setIsActive(result.data.updatedUser.is_active);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const handleBranchEditClick = () => {
    setIsBranchEdit(!isBranchEdit);
  };

  const handleImageChange = async (file) => {
    const formData = new FormData();
    formData.append("document", file);
    try {
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setUpdatedImage(result?.data?.files[0]?.path);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = async (file, ind) => {
    const formData = new FormData();
    formData.append("document", file);
    try {
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setUpdatedImages((prev) => {
          const updatedImagesCopy = [...prev];
          updatedImagesCopy[ind] = result?.data?.files[0]?.path; // Replace the image at the current index
          return updatedImagesCopy;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setDefaultData = () => {
    setValue("name", data?.name);
    setUpdatedImage(data?.logo);
  };

  const setBranchDefaultData = () => {
    setValue2("branch_name", selectedBranch?.name);
    setValue2("fb", selectedBranch?.fb_page);
    setValue2("insta", selectedBranch?.insta);
    setValue2("snap", selectedBranch?.snap);
    setValue2("tiktok", selectedBranch?.tiktok);
    setValue2("website", selectedBranch?.website);
    setValue2("location", `${selectedBranch?.address_1} + ${selectedBranch?.address_2}`);
    setUpdatedImages(selectedBranch?.pictures)
  }

  const fetchData = async () => {
    try {
      const result = await AdminServices.getAllBrands(id);
      if (result.responseCode === 200) {
        setData(result?.data?.brands[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const saveAddress = (address) => {
    setValue("location", address);
  };

  const updateBrandData = async (formData) => {
    const obj = {
      name: formData.name,
      logo: updatedImage
    }
    try {
      const result = await AdminServices.updateBrand(data?._id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setIsEdit(false);
      }
    } catch (err) {
      ErrorToaster(err);
    } finally {
      fetchData();
    }
  };

  const updateBranchData = async (formData) => {
    const obj = {
      name: formData.branch_name,
      pictures: updatedImages,
      fb_page: formData.fb,
      snap: formData.snap,
      tiktok: formData.tiktok,
      website: formData.website,
      address_1: formData.location
    }
    try {
      const result = await AdminServices.updateBranch(selectedBranch?._id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setIsBranchEdit(false);
        setSelectedBranch(null);
      }
    } catch (err) {
      ErrorToaster(err);
    } finally {
      fetchData();
    }
  };

  useEffect(() => {
    let isMounted = true;

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    setDefaultData();
  }, [data]);

  useEffect(() => {
    setBranchDefaultData()
  }, [selectedBranch]);

  return (
    <Fragment>
      <AddressForm
        open={open}
        onClose={() => setOpen(false)}
        defaultData={{ latitude: selectedBranch?.latitude, longitude: selectedBranch?.longitude }}
        save={(data) => saveAddress(data)}
      />
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb
            pageName={"Businesses Detail"}
            status={{ state: state, data: state?.business }}
            handleStatusApproved={handleStatusApproved}
            handleStatusDisapproved={handleStatusDisapproved}
            isActive={isActive}
            onChange={handleStatus}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: 2
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "16px 24px 0px 24px",
              position: "relative",
            }}
          >
            <PageDot top={"30px"} left={0} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ background: Colors.buttonGradient }}
              >
                <ArrowLeft sx={{ color: Colors.white }} />
              </IconButton>
              <UserIcon />
              <Typography
                sx={{
                  color: Colors.white,
                  fontWeight: 600,
                  fontSize: "18px"
                }}
              >
                Brand Information
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            sx={{
              borderTop: `2px solid ${Colors.dark2}`
            }}
          >
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
              component={"form"}
              onSubmit={handleSubmit(updateBrandData)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 3
                }}
              >
                {!isBranchEdit && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 2
                    }}
                  >
                    {isEdit && (
                      <SecondaryButton
                        type={"submit"}
                        title={"Update"}
                      />
                    )}
                    <Tooltip title="Edit">
                      <EditButton
                        isEdit={isEdit}
                        onClick={handleClick}
                      />
                    </Tooltip>
                  </Box>
                )}
                <Box
                  sx={{
                    position: "relative",
                    "&:hover .overlay": {
                      opacity: isEdit ? 1 : 0, // Apply hover effect only when isEdit is true
                    },
                  }}
                >
                  <CardMedia
                    component={"img"}
                    src={baseUrl + updatedImage}
                    sx={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "15px"
                    }}
                  />
                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0, // Default to hidden
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    {isEdit && (
                      <label>
                        <input
                          ref={(input) => (inputRef.current = input)}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }} // Hide the input
                          onChange={(e) => handleImageChange(e.target.files[0])}
                        />
                        <IconButton
                          onClick={() => inputRef.current.click()}
                          sx={{
                            border: `1px solid ${Colors.secondary}`
                          }}
                        >
                          <CloudUpload sx={{ color: Colors.secondary }} />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Brand Name
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("name")}
                      />
                    ) : (
                      <Typography
                        variant='body2'
                        sx={{
                          color: Colors.white
                        }}
                      >
                        {data?.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {state?.data?.user?.email ?? "comapany@gmail.com"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {state?.data?.user?.phone ?? "966 01293012"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                  p: 2,
                  overflow: "auto",
                  height: "522px",
                }}
              >
                <Typography
                  sx={{
                    color: Colors.white,
                    fontWeight: 600,
                    fontSize: "18px"
                  }}
                >
                  Branches
                </Typography>
                {data?.establishments?.map((item, ind) => (
                  <SecondaryCard key={ind} title={item.name} onClick={() => setSelectedBranch(item)} />
                ))}
              </Box>
            </Grid>
            <Grid item md={4.5} component={"form"} onSubmit={handleSubmit2(updateBranchData)}>
              {selectedBranch && (
                <Fragment>
                  {!isEdit && (
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2
                      }}
                    >
                      {isBranchEdit && (
                        <SecondaryButton
                          type={"submit"}
                          title={"Update"}
                        />
                      )}
                      <EditButton
                        onClick={handleBranchEditClick}
                        isEdit={isBranchEdit}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      height: "554px",
                      overflow: "auto"
                    }}
                  >
                    <Grid container spacing={2}
                      sx={{ p: 2 }}
                    >
                      {updatedImages?.map((item, ind) => (
                        <Grid key={ind} item md={ind == 0 ? 12 : 6}>
                          <Box
                            sx={{
                              position: "relative",
                              "&:hover .overlay": {
                                opacity: isBranchEdit ? 1 : 0, // Apply hover effect only when isEdit is true
                              },
                            }}
                          >
                            <CardMedia
                              component={"img"}
                              src={baseUrl + item}
                              sx={{
                                width: "100%",
                                height: ind == 0 ? "250px" : "150px",
                                objectFit: "cover",
                                borderRadius: "15px"
                              }}
                            />
                            <Box
                              className="overlay"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: 0, // Default to hidden
                                transition: "opacity 0.3s ease",
                              }}
                            >
                              {isBranchEdit && (
                                <label>
                                  <input
                                    ref={(input) => (inputRefs.current[ind] = input)}
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }} // Hide the input
                                    onChange={(e) => handleFileChange(e.target.files[0], ind)}
                                  />
                                  <IconButton
                                    onClick={() => inputRefs.current[ind].click()}
                                    sx={{
                                      border: `1px solid ${Colors.secondary}`
                                    }}
                                  >
                                    <CloudUpload sx={{ color: Colors.secondary }} />
                                  </IconButton>
                                </label>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        p: 3
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: `1px solid ${Colors.dark2}`,
                        }}
                      >
                        <Box
                          sx={{
                            p: 1
                          }}
                        >
                          <Typography
                            sx={{
                              color: Colors.dark2,
                              fontSize: "10px"
                            }}
                          >
                            Branch Name
                          </Typography>
                          {isBranchEdit ? (
                            <EditField
                              register={register2("branch_name")}
                            />
                          ) : (
                            <Typography
                              variant='body2'
                              sx={{
                                color: Colors.white
                              }}
                            >
                              {selectedBranch?.name}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          borderBottom: `1px solid ${Colors.dark2}`,
                        }}
                      >
                        <Box
                          sx={{
                            p: 1
                          }}
                        >
                          <Typography
                            sx={{
                              color: Colors.dark2,
                              fontSize: "10px"
                            }}
                          >
                            Branch Type
                          </Typography>
                          {isBranchEdit ? (
                            <EditField
                              register={register2("branch_name")}
                            />
                          ) : (
                            <Typography
                              variant='body2'
                              sx={{
                                color: Colors.white
                              }}
                            >
                              {Capitalize(selectedBranch?.type)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          borderBottom: `1px solid ${Colors.dark2}`,
                        }}
                      >
                        <Box
                          sx={{
                            p: 1
                          }}
                        >
                          <Typography
                            sx={{
                              color: Colors.dark2,
                              fontSize: "10px"
                            }}
                          >
                            Email
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{
                              color: Colors.white
                            }}
                          >
                            {selectedBranch?.email ?? "comapany@gmail.com"}
                          </Typography>
                        </Box>
                      </Box>
                      {selectedBranch?.fb_page && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1,
                            borderBottom: `1px solid ${Colors.dark2}`,
                          }}
                        >
                          <FacebookIcon />
                          <Box
                            sx={{
                              p: 1,
                              width: isBranchEdit ? "100%" : "auto"
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Facebook
                            </Typography>
                            {isBranchEdit ? (
                              <EditField
                                register={register2("fb")}
                              />
                            ) : (
                              <Typography
                                variant='body2'
                                sx={{
                                  color: Colors.white
                                }}
                              >
                                {selectedBranch?.fb_page}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                      {selectedBranch?.insta && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1,
                            borderBottom: `1px solid ${Colors.dark2}`,
                          }}
                        >
                          <InstagramIcon />
                          <Box
                            sx={{
                              p: 1,
                              width: isBranchEdit ? "100%" : "auto"
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Instagram
                            </Typography>
                            {isBranchEdit ? (
                              <EditField
                                register={register2("insta")}
                              />
                            ) : (
                              <Typography
                                variant='body2'
                                sx={{
                                  color: Colors.white
                                }}
                              >
                                {selectedBranch?.insta}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                      {selectedBranch?.snap && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1,
                            borderBottom: `1px solid ${Colors.dark2}`,
                          }}
                        >
                          <SnapchatIcon />
                          <Box
                            sx={{
                              p: 1,
                              width: isBranchEdit ? "100%" : "auto"
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Snapchat
                            </Typography>
                            {isBranchEdit ? (
                              <EditField
                                register={register2("snap")}
                              />
                            ) : (

                              <Typography
                                variant='body2'
                                sx={{
                                  color: Colors.white
                                }}
                              >
                                {selectedBranch?.snap}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                      {selectedBranch?.tiktok && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1
                          }}
                        >
                          <TiktokIcon />
                          <Box
                            sx={{
                              p: 1,
                              width: isBranchEdit ? "100%" : "auto"
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Tiktok
                            </Typography>
                            {isBranchEdit ? (
                              <EditField
                                register={register2("tiktok")}
                              />
                            ) : (
                              <Typography
                                variant='body2'
                                sx={{
                                  color: Colors.white
                                }}
                              >
                                {selectedBranch?.tiktok}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                      {selectedBranch?.timezone && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1
                          }}
                        >
                          <TimezoneIcon />
                          <Box
                            sx={{
                              p: 1
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Timezone
                            </Typography>
                            <Typography
                              variant='body2'
                              sx={{
                                color: Colors.white
                              }}
                            >
                              {selectedBranch?.timezone}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {selectedBranch?.website && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1
                          }}
                        >
                          <WorldWebIcon />
                          <Box
                            sx={{
                              p: 1,
                              width: isBranchEdit ? "100%" : "auto"
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Website
                            </Typography>
                            {isBranchEdit ? (
                              <EditField
                                register={register2("website")}
                              />
                            ) : (
                              <Typography
                                variant='body2'
                                sx={{
                                  color: Colors.white
                                }}
                              >
                                {selectedBranch?.website}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                      {selectedBranch?.address_1 && selectedBranch?.address_2 && selectedBranch?.latitude && selectedBranch?.longitude && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1,
                            cursor: "pointer"
                          }}
                          onClick={() => openMap()}
                        >
                          <MarkerIcon2 />
                          <Box
                            sx={{
                              p: 1,
                              width: isBranchEdit ? "100%" : "auto"
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.dark2,
                                fontSize: "10px"
                              }}
                            >
                              Location
                            </Typography>
                            {isBranchEdit ? (
                              <EditField
                                register={register2("location")}
                                onClick={(e) => { e.stopPropagation(); setOpen(true) }}
                              />
                            ) : (
                              <Typography
                                variant='body2'
                                sx={{
                                  color: Colors.white
                                }}
                              >
                                {`${selectedBranch?.address_1}, ${selectedBranch?.address_2}`}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment >
  )
}

export default BrandDetail;