import { useState } from "react";

function useProvideAuth() {
  const [user, setUser] = useState(localStorage.getItem("token"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [mail, setMail] = useState(localStorage.getItem("mail"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [city_id, setCity_id] = useState(localStorage.getItem("city_id"));

  const userLogin = (data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", data.phone);
    localStorage.setItem("mail", data.email);
    localStorage.setItem("role", data.role);
    localStorage.setItem("city_id", data.city_id);
    setUser(data.token);
    setPhone(data.phone);
    setMail(data.email);
    setRole(data.role);
    setCity_id(data.city_id);
  }

  const userLogout = async () => {
    localStorage.clear();
  };

  return {
    user,
    phone,
    mail,
    role,
    city_id,
    userLogin,
    userLogout
  };
};

export default useProvideAuth;