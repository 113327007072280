import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react';
import BreadCrumb from '../../../components/breadcrumb';
import Colors from '../../../style';
import { useForm } from 'react-hook-form';
import { ArrowCircleUp, UploadFile } from '@mui/icons-material';
import AdminServices from '../../../api/admin/AdminServices';
import { baseUrl } from '../../../../axios';
import { ErrorToaster, SuccessToaster } from '../../../components/toaster';

function Upload() {
  const [images, setImages] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const {
    explore_page,
    home_page_business,
    home_page_member,
    onboarding_screen_1,
    onboarding_screen_2,
    onboarding_screen_3
  } = images

  const imagesData = {
    explore_page: explore_page,
    home_page_business: home_page_business,
    home_page_member: home_page_member,
    onboarding_screen_1: onboarding_screen_1,
    onboarding_screen_2: onboarding_screen_2,
    onboarding_screen_3: onboarding_screen_3
  }

  const uploadImage = async (e, name) => {
    const validTypes = ['image/png', 'image/jpeg', 'image/gif'];
    const maxSize = 500 * 1024; //
    const file = e.target.files[0];
    const formData = new FormData();
    const maxWidth = 393;
    const maxHeight1 = 852;
    const maxHeight2 = 420;
    if (!validTypes.includes(file.type)) {
      ErrorToaster(`File type ${file.type} is not allowed. Only PNG, JPEG, and GIF are accepted.`);
    } else if (file.size > maxSize) {
      ErrorToaster(`File size ${file.name} is too large. Maximum size is 500KB.`);
    } else {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.onload = async () => {
        if (name == "onboarding_screen_1" || name == "onboarding_screen_2" || name == "onboarding_screen_3") {
          if (img.width != maxWidth || img.height != maxHeight1) {
            ErrorToaster(`Maximum allowed dimensions are ${maxWidth}x${maxHeight1}.`);
            URL.revokeObjectURL(objectUrl); // cleanup
          } else {
            formData.append("document", file);
            try {
              const result = await AdminServices.uploadImage(formData);
              if (result.responseCode == 200) {
                const obj = {
                  [name]: result.data.files[0].path
                }
                const updateResult = await AdminServices.updateImages(obj);
                if (updateResult.responseCode == 200) {
                  SuccessToaster(updateResult.message);
                }
              }
            } catch (err) {
              console.log(err);
            } finally {
              getImages();
            }
          }
        }
        if (name == "explore_page" || name == "home_page_business" || name == "home_page_member") {
          if (img.width != maxWidth || img.height != maxHeight2) {
            ErrorToaster(`Maximum allowed dimensions are ${maxWidth}x${maxHeight2}.`);
            URL.revokeObjectURL(objectUrl);
          } else {
            formData.append("document", file);
            try {
              const result = await AdminServices.uploadImage(formData);
              if (result.responseCode == 200) {
                const obj = {
                  [name]: result.data.files[0].path
                }
                const updateResult = await AdminServices.updateImages(obj);
                if (updateResult.responseCode == 200) {
                  SuccessToaster(updateResult.message);
                }
              }
            } catch (err) {
              console.log(err);
            } finally {
              getImages();
            }
          }
        }
      }
      img.src = objectUrl;
    }
  };

  const getImages = async () => {
    try {
      const result = await AdminServices.getImages();
      if (result.responseCode == 200) {
        setImages(result?.data.system)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getImages();
  }, [])

  return (
    <Fragment>
      <Grid container alignItems={"center"} justifyContent={"center"} rowGap={2}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Upload Image"} />
        </Grid>
        <Grid item md={12}>
          <Container>
            <Grid container spacing={2} pb={2}>
              {Object.entries(imagesData).map(([key, value], ind) => (
                <Grid key={key} item md={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      position: 'relative'
                    }}
                    onMouseEnter={() => setHoveredIndex(ind)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Nunito Sans',
                        color: Colors.white,
                      }}
                    >
                      {ind === 0 ? 'Explore Page (393 x 420)'
                        : ind === 1 ? 'Home Page Business (393 x 420)'
                          : ind === 2 ? 'Home Page Member (393 x 420)'
                            : ind === 3 ? 'Onboarding Screen 1 (393 x 852)'
                              : ind === 4 ? 'Onboarding Screen 2 (393 x 852)'
                                : ind === 5 ? 'Onboarding Screen 3 (393 x 852)'
                                  : ''
                      }
                    </Typography>
                    <Box
                      sx={{
                        height: '300px',
                        width: '100%',
                        position: 'relative',
                        '&:hover .overlay': {
                          opacity: 1
                        }

                      }}
                    >
                      <CardMedia
                        component={'img'}
                        src={baseUrl + value}
                        sx={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px'
                        }}
                      />
                      {hoveredIndex === ind && (
                        <Box
                          className="overlay"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            opacity: 0,
                            transition: 'opacity 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px'
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              backgroundColor: "transparent",
                              color: Colors.white,
                              flexDirection: "column",
                              textTransform: "capitalize",
                              ":hover": {
                                background: "transparent"
                              }
                            }}
                          >
                            <ArrowCircleUp sx={{ fontSize: "48px" }} />
                            <Typography>
                              Upload
                            </Typography>
                            <input
                              type="file"
                              hidden
                              onChange={(e) => uploadImage(e, key)}
                            />
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Upload