import React, { Fragment, useEffect, useState } from 'react';
import { Box, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import Colors from '../../../style';
import BreadCrumb from '../../../components/breadcrumb';
import { InputSelectField } from '../../../components/inputfields';
import { PrimaryButton } from '../../../components/buttons';
import { useForm } from 'react-hook-form';
import "@fontsource/nunito-sans"
import AdminServices from '../../../api/admin/AdminServices';
import { VisibilityOffOutlined, VisibilityOutlined, Upload } from '@mui/icons-material';
import Images from '../../../assets/images';
import { baseUrl } from '../../../../axios';
import { SuccessToaster } from '../../../components/toaster';

function CreateAdmin() {
  const { register, handleSubmit, formState: { errors }, reset, getValues, watch, trigger } = useForm();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [image, setImage] = useState("");
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCountries = async () => {
    try {
      const result = await AdminServices.getCountries();
      if (result.responseCode == 200) {
        setCountries(result.data.countries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCities = async (id) => {
    try {
      const result = await AdminServices.getCities(id);
      if (result.responseCode == 200) {
        setCities(result.data.cities);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("document", file);
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        setImage(result.data.files[0].path);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitForm = async (formData) => {
    setLoading(true);
    const obj = {
      first_name: formData.name,
      phone: formData.phone,
      email: formData.email,
      password: formData.password,
      picture: image,
      city_id: formData.city._id
    };
    try {
      const result = await AdminServices.createSubAdmin(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      reset();
      setImage("");
      setSelectedCountry(null);
      setSelectedCity(null);
    }
  };

  watch("password");

  useEffect(() => {
    getCountries();
  }, [])

  return (
    <Fragment>
      <Grid container justifyContent={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Create Admin"} />
        </Grid>
        <Grid item md={10} mt={2}>
          <Grid
            component={"form"}
            container
            rowGap={2}
            sx={{
              background: Colors.dark1,
              borderRadius: "12px",
              p: 3,
              boxShadow: `0px 0px 20px 0px ${Colors.shadowColor + "B3"}`,
            }}
            onSubmit={handleSubmit(submitForm)}
          >
            <Grid item md={12}>
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: 1
                    }}
                  >
                    <Box
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      position="relative"
                      display="inline-block"
                    >
                      <CardMedia
                        component="img"
                        image={image == "" ? Images.defaultUser : baseUrl + image}
                        alt="Default Image"
                        sx={{ width: '75px', height: '75px', borderRadius: "50%" }}
                      />
                      {hover && (
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          bgcolor="rgba(0, 0, 0, 0.5)"
                          borderRadius={"50%"}
                        >
                          <input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              uploadImage(file);
                            }}
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                              <Upload style={{ color: 'white', fontSize: '2rem' }} />
                            </IconButton>
                          </label>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                      }}
                    >
                      <Typography sx={{ color: Colors.white, fontFamily: "Nunito Sans", fontSize: "20px" }}>
                        Profile Image
                      </Typography>
                      <Typography sx={{ color: Colors.white, fontFamily: "Nunito Sans", }} variant='caption'>(Upload png or jpeg only)</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Name"}
                    register={register("name", {
                      required: "Name"
                    })}
                    error={errors?.name && true}
                    helperText={errors?.name?.message}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Phone"}
                    register={register("phone")}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Email"}
                    register={register("email", {
                      required: "Email"
                    })}
                    error={errors?.email && true}
                    helperText={errors?.email?.message}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Password"}
                    type={visiblePassword ? "text" : "password"}
                    register={register("password", {
                      required: "Password"
                    })}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setVisiblePassword(!visiblePassword)}
                        >
                          {visiblePassword ? <VisibilityOutlined sx={{ color: Colors.primary }} />
                            : <VisibilityOffOutlined sx={{ color: Colors.primary }} />}
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Confirm Password"}
                    type={visibleConfirmPassword ? "text" : "password"}
                    register={register("cnfpassword", {
                      required: "Confirm Password",
                      validate: (value) => {
                        const password = getValues("password");
                        return value === password || "Passwords do not match";
                      },
                      onChange: () => {
                        trigger("cnfpassword"); // Re-validate confirm password field on change
                      }
                    })}
                    error={!!errors?.cnfpassword}
                    helperText={errors?.cnfpassword?.message}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
                        >
                          {visibleConfirmPassword ? <VisibilityOutlined sx={{ color: Colors.primary }} />
                            : <VisibilityOffOutlined sx={{ color: Colors.primary }} />}
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    select={true}
                    data={countries}
                    value={selectedCountry}
                    fullWidth={true}
                    label={"Country"}
                    register={register("country", {
                      required: selectedCountry == null ? "Country" : false,
                      onChange: (e) => {
                        const id = e.target.value.country_id;
                        setSelectedCountry(e.target.value)
                        getCities(id);
                      },
                    })}
                    error={errors?.country && true}
                    helperText={errors?.country?.message}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    select={true}
                    data={cities}
                    value={selectedCity}
                    label={"City"}
                    register={register("city", {
                      required: selectedCity == null ? "City" : false,
                      onChange: (e) => {
                        setSelectedCity(e.target.value)
                      }
                    })}
                    error={errors?.city && true}
                    helperText={errors?.city?.message}
                  />
                </Grid>
                <Grid item md={12}>
                  <PrimaryButton
                    fullWidth
                    loading={loading}
                    title={"Submit"}
                    type={"submit"}
                    buttonStyle={{ p: "14px 24px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default CreateAdmin