import { Fragment, useState } from "react";
import Header from "./header";
import SideNav from "./sideNav";
import { Outlet } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import Colors from "../style";

function Layout() {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  }

  return (
    <Fragment>
      <Header onClick={handleDrawerOpen} />
      <Box
        component={"main"}
        sx={{
          display: "flex",
          minHeight: "100vh",
          transition: "all .3s ease-in-out !important",
          background: Colors.dark3,
        }}
      >
        <SideNav open={open} />
        <Box
          sx={{
            mt: "88px",
            flexGrow: 1,
            height: "100%",
            transition: "all .3s ease-in-out !important",
            minWidth: "calc(100% - 290px)"
          }}
        >
          <Grid container>
            <Grid item md={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Layout;