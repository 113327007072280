import { Box, Button, Grid, Typography } from "@mui/material";
import Colors from "../../style";
import { FilterIcon, ResetIcon } from "../../assets/icons";
import { Fragment } from "react";

function Filter({ items, onClick }) {
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        background: Colors.dark1,
        borderRadius: "15px"
      }}
    >
      <Grid item md={0.4}
        sx={{
          borderRight: `1px solid ${Colors.dark2}`
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            width: "100%",
            py: "8px"
          }}
        >
          <FilterIcon />
        </Box>
      </Grid>
      {items && items.map((item, ind) => (
        <Fragment key={ind}>
          {item}
        </Fragment>
      ))}
      <Grid item md={0.4}>
        <Button
          sx={{
            textTransform: "capitalize",
            minWidth: "100%",
            gap: 1,
            p: "4px",
            borderRadius: "50%"
          }}
          onClick={onClick}
        >
          <ResetIcon />
        </Button>
      </Grid>
    </Grid>
  )
}

export default Filter;