import { Fragment } from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { DashboardIcon } from "../../assets/icons";
import Colors from "../../style";
import { PrimaryButton, SecondaryButton, ToggleButton } from "../buttons";

function BreadCrumb({ pageName, status, handleStatusApproved, handleStatusDisapproved, isActive, onChange, disabled }) {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Breadcrumbs
        separator="/"
        sx={{
          color: Colors.white,
        }}
      >
        <Link
          underline="hover"
          sx={{
            gap: 1,
            color: Colors.white,
            display: 'flex',
            alignItems: 'center',
          }}
          href="/"
        >
          <DashboardIcon />
          Home
        </Link>
        <Link
          underline="none"
          sx={{
            color: Colors.white,
          }}
        >
          {pageName}
        </Link>
      </Breadcrumbs>
      {status && status?.state?.navigateFrom == "request" ? (
        <Box
          sx={{
            display: "flex",
            gap: 2
          }}
        >
          {status?.data?.is_approved == null ? (
            <Fragment>
              <SecondaryButton
                title={"Reject"}
                onClick={handleStatusDisapproved}
              />
              <PrimaryButton
                title={"Accept"}
                onClick={handleStatusApproved}
              />
            </Fragment>
          ) : status?.data?.is_approved == true ? (
            <PrimaryButton
              title={"Accepted"}
              onClick={handleStatusApproved}
              disabled={true}
              buttonStyle={{
                "&.Mui-disabled": {
                  color: Colors.black
                }
              }}
            />
          ) : (
            <SecondaryButton
              title={"Rejected"}
              onClick={handleStatusDisapproved}
              disabled={true}
            />
          )}
        </Box>
      ) : status?.state?.navigateFrom == "list" ? (
        <Box>
          <ToggleButton
            disabled={disabled}
            checked={isActive}
            onChange={onChange}
            label={isActive ? "Active" : "Inactive"}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box >
  )
}

export default BreadCrumb;