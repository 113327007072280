import React, { Fragment, useState, useEffect } from 'react';
import { Close, KeyboardArrowLeft, KeyboardArrowRight, MoreHoriz, SaveAlt } from '@mui/icons-material';
import { Box, ButtonGroup, CardMedia, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Grid, IconButton, ListItemText, Menu, MenuItem, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Colors from '../../../../style';
import BreadCrumb from '../../../../components/breadcrumb';
import Filter from '../../../../components/filter';
import { DateField, InputField } from '../../../../components/inputfields';
import { PrimaryButton } from '../../../../components/buttons';
import { PageDot } from '../../../../components/UI';
import { BusinessIcon2 } from '../../../../assets/icons';
import AdminServices from '../../../../api/admin/AdminServices';
import { Capitalize } from '../../../../utils/Capitalize';
import { baseUrl } from '../../../../../axios';
import { ErrorToaster } from '../../../../components/toaster';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

const ITEM_HEIGHT = 48;

const tableHead = [
  {
    name: "ID",
    sortOrder: { column: "member_id", direction: 'asc' },
    visible: true
  },
  {
    name: "Name",
    sortOrder: { column: "name", direction: 'asc' },
    visible: true
  },
  {
    name: "Gender",
    sortOrder: { column: "gender", direction: 'asc' },
    visible: true
  },
  {
    name: "City",
    sortOrder: { column: "city", direction: 'asc' },
    visible: true
  },
  {
    name: "Review Status",
    sortOrder: { column: "review_status", direction: 'asc' },
    visible: true
  },
  {
    name: "Action",
    sortOrder: { column: "action", direction: 'asc' },
    visible: true
  },
];

const statuses = ["Status", "Pending", "Submitted"];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBack = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNext = (event) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        sx={{
          borderRadius: 2,
          background: Colors.dark1,
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={page == 0}
          aria-label="previous page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReviewModal({ open, handleClose, image }) {

  const downloadReview = async () => {
    try {
      window.location.href = `${baseUrl}/api/download-media?path=${image}&name=review.png`
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          background: Colors.dark3
        }
      }}
    >
      <DialogTitle
        sx={{
          color: Colors.white,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        {"REVIEW"}
        <Box
          sx={{
            display: "flex",
            gap: 1
          }}
        >
          <IconButton
            sx={{
              background: Colors.buttonGradient
            }}
            onClick={() => downloadReview()}
          >
            <SaveAlt
              sx={{
                color: Colors.white,
                width: "20px",
                height: "20px"
              }}
            />
          </IconButton>
          <IconButton
            sx={{
              background: Colors.buttonGradient
            }}
            onClick={handleClose}
          >
            <Close
              sx={{
                color: Colors.white,
                width: "20px",
                height: "20px"
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <CardMedia
          component={"img"}
          src={baseUrl + image}
          sx={{
            width: "350px",
            height: "350px",
            objectFit: "contain",
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

function InvitedMemberList() {
  const { state } = useLocation();
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null)
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(tableHead);
  const [selectedStatus, setSelectedStatus] = useState("Status");

  const handleToggleColumn = (column) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleSort = (column) => {
    setColumns((prevColumns) => {
      const newColumns = prevColumns.map((col) =>
        col.sortOrder.column === column
          ? { ...col, sortOrder: { column, direction: col.sortOrder.direction === 'asc' ? 'desc' : 'asc' } }
          : col
      );

      const sortedRows = [...rows].sort((a, b) => {
        const col = newColumns.find(col => col.sortOrder.column === column);
        const direction = col.sortOrder.direction;
        if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
        return 0;
      });

      setRows(sortedRows);
      return newColumns;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getInvitedData(newPage + 1)
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const getInvitedData = async (page, limit) => {
    try {
      const result = await AdminServices.getInvitedMembers(
        page ? page : 1,
        limit ? limit : 10,
        state?.id
      );
      if (result.responseCode == 200) {
        setCount(result?.data?.totalmembers);
        const shallowCopy = result?.data?.members?.map((item) => {
          const obj = { ...item };
          tableHead.forEach((value) => {
            if (value.name == "Action") {
              obj[value.sortOrder.column] = "action";
            } else if (value.name == "ID") {
              obj[value.sortOrder.column] = item.member[value.sortOrder.column];
            } else if (value.name == "Name") {
              let fullName = `${item.user.first_name} ${item.user.last_name}`
              obj[value.sortOrder.column] = fullName;
            } else if (value.name == "Gender") {
              obj[value.sortOrder.column] = Capitalize(item[value.sortOrder.column])
            } else if (value.name == "City") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else if (value.name == "Status") {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            } else {
              obj[value.sortOrder.column] = item[value.sortOrder.column];
            }
          });
          obj.visible = true;
          return obj;
        })
        setRows(shallowCopy);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpen = (data) => {
    if (data?.review_status == "submitted") {
      setOpen(true);
      setImage(data?.review_image);
    } else if (data?.review_status == "pending") {
      ErrorToaster("Review is not submitted yet")
    }
  };

  const handleClose = () => {
    setOpen(false);
    setImage(null);
  };

  const handleDownload = () => {
    const head = tableHead.filter((item) => item.name != "Action").map((item) => item.name);
    const data = rows.map(item => [
      item.member_id,
      `${item.user.first_name} ${item.user.last_name}`,
      item.gender,
      item.city,
      item.review_status,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([head, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the file using FileSaver.js
    saveAs(new Blob([buf]), "invitedMembers.xlsx");
  };

  useEffect(() => {
    if (state?.id) {
      getInvitedData()
    }
  }, []);

  return (
    <Fragment>
      <ReviewModal
        open={open}
        image={image}
        handleClose={handleClose}
      />
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Invited Members"} />
        </Grid>
        <Grid item md={9.5}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Filter items={
              [
                <Grid item md={3}
                  sx={{
                    borderRight: `1px solid ${Colors.dark2}`
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%"
                    }}
                  >
                    <InputField
                      fullWidth={true}
                      placeholder={"ID"}
                    />
                  </Box>
                </Grid>,

                <Grid item md={4.5}
                  sx={{
                    borderRight: `1px solid ${Colors.dark2}`
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%"
                    }}
                  >
                    <InputField
                      placeholder={"Name"}
                      fullWidth={true}
                    />
                  </Box>
                </Grid>,

                <Grid item md={3}
                  sx={{
                    borderRight: `1px solid ${Colors.dark2}`
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%"
                    }}
                  >
                    <InputField
                      value={selectedStatus}
                      fullWidth={true}
                      select={true}
                      options={statuses}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value != "Status") {
                          setSelectedStatus(e.target.value);
                        }
                      }}
                    />
                  </Box>
                </Grid>,
              ]
            }
            />
          </Box>
        </Grid>
        <Grid item md={1.5}>
          <Box
            sx={{ p: 2 }}
          >
            <PrimaryButton
              title={"Search"}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: "0px 16px"
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            p: "16px 24px 8px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px",
            height: "calc(100vh - 293px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative"
            }}
          >
            <PageDot top={"14px"} left={"-24px"} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <BusinessIcon2 />
              <Typography sx={{ color: Colors.white }}>
                Invited Members
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1
              }}
            >
              <IconButton
                sx={{
                  background: Colors.buttonGradient
                }}
                onClick={() => handleDownload()}
              >
                <SaveAlt sx={{ color: Colors.white }} />
              </IconButton>
              <IconButton
                onClick={handleSettingsClick}
              >
                <MoreHoriz sx={{ color: Colors.white }} />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleSettingsClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    background: Colors.dark3,
                    color: Colors.white,
                    overflow: 'visible',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: Colors.dark3,
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 1,
                    },
                    ".MuiList-root": {
                      p: 0
                    }
                  }
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box
                component={"div"}
                sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
              >
                {columns.map((col) => (
                  <MenuItem key={col.name}>
                    <Checkbox
                      sx={{
                        color: `${Colors.primary} !important`
                      }}
                      checked={col.visible}
                      onChange={() => handleToggleColumn(col.name)}
                    />
                    <ListItemText
                      primary={col.name}
                    />
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: Colors.dark3
                }}
              >
                <TableRow>
                  {columns.filter((col) => col.visible).map((col, colInd, visibleColumns) => (
                    <TableCell
                      key={colInd}
                      sx={{
                        p: "10px 16px",
                        display: "table-cell",
                        borderBottom: "none",
                        borderRadius: colInd == 0
                          ? "20px 0 0 20px"
                          : colInd == visibleColumns.length - 1
                            ? "0 20px 20px 0"
                            : "none",
                      }}
                    >
                      {col.name != "Action" ? (
                        <TableSortLabel
                          active={col.sortOrder.column == col.name}
                          direction={col.sortOrder.direction}
                          onClick={() => handleSort(col.sortOrder.column)}
                          sx={{
                            "svg": {
                              fontSize: "12px",
                              color: `${Colors.white} !important`,
                            }
                          }}
                        >
                          <Box
                            sx={{
                              color: Colors.white,
                            }}
                          >
                            {col.name}
                          </Box>
                        </TableSortLabel>
                      ) : (
                        <Box
                          sx={{
                            color: Colors.white,
                          }}
                        >
                          {col.name}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, ind) => (
                  <TableRow key={ind}>
                    {columns.map((col, colInd) => col.visible && (
                      col.name == "Action" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: Colors.white
                            }}
                            onClick={() => handleOpen(row)}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </TableCell>
                      ) : col.name == "Name" ? (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          <Tooltip title={row[col.sortOrder.column]}>
                            {row[col.sortOrder.column]?.length > 16
                              ? row[col.sortOrder.column]?.slice(0, 16) + "..."
                              : row[col.sortOrder.column]
                            }
                          </Tooltip>
                        </TableCell>
                      ) : col.name == "Review Status" ? (
                        <TableCell key={colInd}
                          sx={{
                            p: "8px 16px"
                          }}
                        >
                          <Chip
                            label={Capitalize(row.review_status)}
                            sx={{
                              background: row.review_status == "submitted"
                                ? Colors.success + 19
                                : Colors.danger + 19,
                              color: row.review_status == "submitted"
                                ? Colors.success
                                : Colors.danger
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={colInd}
                          sx={{
                            color: Colors.white,
                            p: "8px 16px"
                          }}
                        >
                          {row[col.sortOrder.column]}
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Table>
        <TablePagination
          sx={{
            borderBottom: "none",
            borderRadius: "12px",
            color: Colors.white,
            ".MuiTablePagination-spacer": {
              flex: 1,
            },
            ".MuiToolbar-root": {
              gap: "12px",
              paddingRight: "24px"
            }
          }}
          // rowsPerPageOptions={[10, 20, 30]}
          colSpan={12}
          count={count}
          rowsPerPage={limit}
          page={page}
          labelRowsPerPage={"Showing"}
          slotProps={{
            select: {
              sx: {
                display: "none"
              }
            }
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </Fragment>
  )
}

export default InvitedMemberList