import {
  DashboardIcon,
  MemberIcon,
  BusinessIcon,
  OfferIcon,
  InviteIcon,
  NotificationIcon,
  ChatIcon
} from "./app/assets/icons";
import { SettingsOutlined, AdminPanelSettingsOutlined } from "@mui/icons-material";

const Navigation = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/"
  },
  {
    name: "Admins",
    icon: <AdminPanelSettingsOutlined sx={{ fontSize: "30px" }} />,
    subMenu: [
      {
        name: "Create Admin",
        path: "/create-admin",
      },
      {
        name: "Admins List",
        path: "/admin-list",
      },
    ]
  },
  {
    name: "Members",
    icon: <MemberIcon />,
    subMenu: [
      {
        name: "Members List",
        path: "/members-list",
      },
      {
        name: "Members Request",
        path: "/members-request",
      },
    ]
  },
  {
    name: "Businesses",
    icon: <BusinessIcon />,
    subMenu: [
      {
        name: "Businesses List",
        path: "/businesses-list",
      },
      {
        name: "Businesses Request",
        path: "/businesses-request",
      },
      {
        name: "Membership Request",
        path: "/membership-request",
      },
    ]
  },
  {
    name: "Offers",
    icon: <OfferIcon />,
    subMenu: [
      {
        name: "Offers List",
        path: "/offers-list",
      },
      {
        name: "Offers Request",
        path: "/offers-request",
      },
    ]
  },
  {
    name: "Events",
    icon: <InviteIcon />,
    subMenu: [
      {
        name: "Invites List",
        path: "/invites-list",
      },
      {
        name: "Events List",
        path: "/events-list",
      },
    ]
  },
  {
    name: "Settings",
    icon: <SettingsOutlined sx={{ fontSize: "30px" }} />,
    subMenu: [
      {
        name: "Categories",
        path: "/settings",
      },
      {
        name: "Upload Images",
        path: "/upload-images",
      },
    ]
  },
  {
    name: "Notification",
    icon: <NotificationIcon />,
    path: "/notifications"
  },
  {
    name: "Chat",
    icon: <ChatIcon />,
    path: "/chat"
  },
];

export default Navigation;