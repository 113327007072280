import React, { Fragment, useState, useEffect } from 'react'
import { Box, CardMedia, CircularProgress, Grid, Typography, Tooltip, IconButton, Menu, MenuItem, ListItemText, Avatar } from '@mui/material';
import Colors from '../../style';
import BreadCrumb from '../../components/breadcrumb';
import {
  GroupIcon,
  TotalBusinessIcon,
  EventIcon,
  PersonAddIcon,
  UserIcon2,
  ClockIcon2,
  MarkerIcon3
} from '../../assets/icons';
import { PrimaryCard } from '../../components/cards';
import MyGraph from '../../components/graph';
import "@fontsource/sora";
import { MoreHoriz } from '@mui/icons-material';
import AdminServices from '../../api/admin/AdminServices';
import { baseUrl } from '../../../axios';
import moment from 'moment';
import { PageDot } from '../../components/UI';
import { PrimaryButton } from '../../components/buttons';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;

const dropdownCategories = [
  {
    key: 0,
    content: "January",
    value: "January",
  },
  {
    key: 1,
    content: "February",
    value: "February",
  },
  {
    key: 2,
    content: "March",
    value: "March",
  },
  {
    key: 3,
    content: "April",
    value: "April",
  },
  {
    key: 4,
    content: "May",
    value: "May",
  },
  {
    key: 5,
    content: "June",
    value: "June",
  },
  {
    key: 6,
    content: "July",
    value: "July",
  },
  {
    key: 7,
    content: "August",
    value: "August",
  },
  {
    key: 8,
    content: "September",
    value: "September",
  },
  {
    key: 9,
    content: "October",
    value: "October",
  },
  {
    key: 10,
    content: "November",
    value: "November",
  },
  {
    key: 11,
    content: "December",
    value: "December",
  },
];

function Dashboard() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [events, setEvents] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const navigate = useNavigate();

  const cardData = [
    {
      counting: `${stats?.members}+`,
      title: "Total Members",
      icon: <GroupIcon width={"30px"} height={"30px"} />
    },
    {
      counting: `${stats?.businesses}+`,
      title: "Total Business",
      icon: <TotalBusinessIcon />
    },
    {
      counting: `${stats?.events}+`,
      title: "Total Events",
      icon: <EventIcon width={"30px"} height={"30px"} />
    },
    {
      counting: `${stats?.offers}+`,
      title: "Total Request",
      icon: <PersonAddIcon />
    },
  ];

  const getStats = async () => {
    setLoading(true);
    try {
      const result = await AdminServices.getStats();
      if (result.responseCode == 200) {
        setStats(result?.data?.stats);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const getEventsData = async () => {
    try {
      const result = await AdminServices.getAllEvents(1, 4, "accepted", "", "", "", "", "");
      if (result.responseCode == 200) {
        setEvents(result.data.events);
      }
    } catch (err) {
      console.log(err)
    }
  }

  const transformData = (stats) => {
    const data = [];
    const today = moment().startOf('day');

    const eventsDailyStats = stats.eventsDailyStats || [];
    const offersDailyStats = stats.offersDailyStats || [];

    const maxDays = 30; // We want to show data for the last 30 days
    const interval = 5; // We want to show data at intervals of 5 days

    for (let i = 0; i <= maxDays; i += interval) {
      const startDate = today.clone().subtract(i + interval, 'days').startOf('day');
      const endDate = today.clone().subtract(i, 'days').endOf('day');
      const eventCount = eventsDailyStats.reduce((sum, event) => {
        const eventDate = moment(event.date);
        if (eventDate.isBetween(startDate, endDate, null, '[]')) {
          return sum + event.count;
        }
        return sum;
      }, 0);
      const offerCount = offersDailyStats.reduce((sum, offer) => {
        const offerDate = moment(offer.date);
        if (offerDate.isBetween(startDate, endDate, null, '[]')) {
          return sum + offer.count;
        }
        return sum;
      }, 0);
      data.push({
        name: i.toString(),
        events: eventCount,
        offers: offerCount,
      });
    }
    return data;
  };

  const handleSettingsClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const handleChange = (e) => {
    setSelectedMonth(e);
    handleSettingsClose();
  }

  useEffect(() => {
    getStats();
    getEventsData();
  }, []);

  useEffect(() => {
    if (stats) {
      const transformedData = transformData(stats);
      setData(transformedData);
    }
  }, [stats]);

  useEffect(() => {
    const currentMonth = new Date().getMonth(); // getMonth() returns 0 for January, 1 for February, etc.
    setSelectedMonth(dropdownCategories[currentMonth].value);
  }, []);

  return (
    <Fragment>
      <Grid container>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Dashboard"} />
        </Grid>
        <Grid item md={12}>
          {loading ? (
            <Box sx={{ height: "78vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress sx={{ color: Colors.secondary }} />
            </Box>
          ) : (
            <Grid container p={3} spacing={3}>
              {stats != null && cardData.map((item, ind) => (
                <Grid key={ind} item md={3}>
                  <PrimaryCard
                    counting={item.counting}
                    title={item.title}
                    icon={item.icon}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {stats != null && (
          <Grid item md={12}>
            <Grid container p={3} pt={0} spacing={3}>
              <Grid item md={6}
                sx={{
                  position: "relative"
                }}
              >
                <Box
                  sx={{
                    background: Colors.dark1,
                    borderRadius: "20px",
                    p: "24px 24px 24px 0px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: "0px 0px 24px 24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2
                      }}
                    >
                      <GroupIcon width={"20px"} height={"20px"} />
                      <Typography
                        sx={{
                          fontFamily: "Sora",
                          fontWeight: 700,
                          fontSize: "18px",
                          color: Colors.white
                        }}
                      >
                        Total Members Activity
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                      }}
                    >
                      <Box
                        sx={{
                          background: Colors.dark2,
                          borderRadius: "4px",
                          p: "10px 24px"
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Sora",
                            fontSize: "12px",
                            color: Colors.white,
                          }}
                        >
                          {selectedMonth}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={handleSettingsClick}
                      >
                        <MoreHoriz sx={{ color: Colors.white }} />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchor}
                        open={Boolean(anchor)}
                        onClose={handleSettingsClose}
                        slotProps={{
                          paper: {
                            elevation: 0,
                            sx: {
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              background: Colors.dark3,
                              color: Colors.white,
                              overflow: 'visible',
                              '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 15,
                                width: 10,
                                height: 10,
                                bgcolor: Colors.dark3,
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 1,
                              },
                              ".MuiList-root": {
                                p: 0
                              }
                            }
                          }
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <Box
                          component={"div"}
                          sx={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}
                        >
                          {dropdownCategories.map((col) => (
                            <MenuItem key={col.key} value={col.value} onClick={() => handleChange(col.value)}>
                              {col.content}
                            </MenuItem>
                          ))}
                        </Box>
                      </Menu>
                    </Box>
                  </Box>
                  <MyGraph data={data} />
                </Box>
                <PageDot top={"60px"} />
              </Grid>
              <Grid item md={6}
                sx={{
                  position: "relative"
                }}
              >
                <Box
                  sx={{
                    background: Colors.dark1,
                    borderRadius: "20px",
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "26px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2
                      }}
                    >
                      <EventIcon width={"20px"} height={"20px"} />
                      <Typography
                        sx={{
                          fontFamily: "Sora",
                          fontWeight: 700,
                          fontSize: "18px",
                          color: Colors.white
                        }}
                      >
                        Events
                      </Typography>
                    </Box>
                    <PrimaryButton
                      title={"View All"}
                      onClick={() => navigate("/events-list")}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1
                    }}
                  >
                    {events.map((item) => {
                      const startTimeMinutes = item?.start_time;
                      const endTimeMinutes = item?.end_time;
                      const startOfDay = moment().startOf('day');
                      const startTime = startOfDay.clone().add(startTimeMinutes, 'minutes');
                      const endTime = startOfDay.clone().add(endTimeMinutes, 'minutes');
                      return (
                        <Box key={item._id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            borderBottom: `1px solid ${Colors.dark2}`,
                            pb: "15px"
                          }}
                        >
                          <Avatar
                            src={baseUrl + item.business_logo}
                            sx={{
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Sora",
                                fontWeight: 600,
                                color: "transparent",
                                background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
                                "-webkit-background-clip": "text",
                                "-webkit-text-fill-color": "transparent",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1
                                }}
                              >
                                <UserIcon2 />
                                <Tooltip
                                  arrow
                                  title={item.bussiness_name}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Sora",
                                      fontSize: "12px",
                                      color: Colors.dark2,
                                      width: "100px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.bussiness_name}
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1
                                }}
                              >
                                <ClockIcon2 />
                                <Tooltip
                                  arrow
                                  title={
                                    `${moment(startTime).format("HH:mm A")} - ${moment(endTime).format("HH:mm A")}`
                                  }
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Sora",
                                      fontSize: "12px",
                                      color: Colors.dark2,
                                      width: "100px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {`
                                  ${moment(startTime).format("HH:mm A")} - ${moment(endTime).format("HH:mm A")}
                                `}
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1
                                }}
                              >
                                <MarkerIcon3 />
                                <Tooltip
                                  arrow
                                  title={item?.country}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Sora",
                                      fontSize: "12px",
                                      color: Colors.dark2,
                                      width: "100px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.country}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
                <PageDot top={"60px"} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

export default Dashboard;