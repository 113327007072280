import { Box, CardMedia, IconButton, Typography } from "@mui/material"
import Colors from "../../style"
import { ArrowRight, TrendingFlat } from "@mui/icons-material"
import { BusinessIcon } from "../../assets/icons"

export const PrimaryCard = (props) => {
  return (
    <Box
      component={"div"}
      sx={{
        transition: "all .3s ease-in-out",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 3,
        px: 2,
        background: Colors.dark1,
        boxShadow: `0px 0px 20px 0px ${Colors.shadowColor + "B3"}`,
        borderRadius: "15px",
        ":hover": {
          background: Colors.buttonGradient,
          ".card-text-primary": {
            background: "transparent",
            "-webkit-text-fill-color": Colors.dark1,
          },
          ".card-text-secondary": {
            color: Colors.dark1
          },
          "svg": {
            "path": {
              fill: Colors.dark1
            }
          }
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1
        }}
      >
        <Typography
          className="card-text-primary"
          sx={{
            fontSize: "30px",
            fontWeight: 700,
            color: "transparent",
            background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        >
          {props.counting}
        </Typography>
        <Typography
          className="card-text-secondary"
          sx={{
            color: Colors.dark2
          }}
        >
          {props.title}
        </Typography>
      </Box>
      <Box>
        {props.icon}
      </Box>
    </Box>
  )
}

export const SecondaryCard = (props) => {
  return (
    <Box
      component={"div"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        background: Colors.buttonGradient,
        borderRadius: "15px",
        boxShadow: `0px 0px 20px 0px ${Colors.shadowColor + "B3"}`,
        ":hover": {
          cursor: "pointer",
          background: Colors.dark2,
        }
      }}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2
        }}
      >
        <Box>
          <BusinessIcon />
        </Box>
        <Typography
          sx={{
            color: Colors.white,
            fontSize: "16px",
          }}
        >
          {props.title}
        </Typography>
      </Box>
      <Box>
        <IconButton>
          <ArrowRight sx={{ color: Colors.white }} />
        </IconButton>
      </Box>
    </Box>
  )
}