import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import adminRoutes from './app/routes/admin';
import publicRoutes from './app/routes/public';
import Layout from './app/layout';
import { ToasterContainer } from './app/components/toaster';
import useAuth from './app/hooks/useAuth';
import './App.css';
import "@fontsource/sora";
import "@fontsource/nunito-sans";
import "@fontsource/poppins";
import "@fontsource/poppins/700.css"
import "@fontsource/sora/700.css"

const theme = createTheme({
  typography: {
    fontFamily: "sora, Nunito Sans, Poppins, sans-serif",
    h1: {
      fontSize: "72px",
    },
    h2: {
      fontSize: "60px",
    },
    h3: {
      fontSize: "48px",
    },
    h4: {
      fontSize: "36px",
    },
    h5: {
      fontSize: "24px",
    },
    h6: {
      fontSize: "18px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontSize: "14px",
    },
    caption: {
      fontSize: "12px",
    },
  },
});

function App() {
  const { user } = useAuth();
  return (
    <ThemeProvider theme={theme}>
      <ToasterContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={user ? <Layout /> : <Navigate to="/login" />}>
            {adminRoutes.map((item, i) => (
              <Route key={i} path={item.path} element={item.component} />
            ))}
          </Route>
          <Route path='/'>
            {publicRoutes.map((item, i) => (
              <Route key={i} path={item.path} element={item.component} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
