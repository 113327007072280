import { Box, Button, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Colors from "../../style";
import { Close, Edit, Search } from "@mui/icons-material";

const ToggleSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: Colors.dark1,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    background: Colors.dark1
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    background: Colors.buttonGradient,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const PrimaryButton = (props) => {
  return (
    <Button
      sx={{
        fontFamily: "Poppins",
        background: Colors.buttonGradient,
        textTransform: "capitalize",
        color: Colors.black,
        borderRadius: "15px",
        p: "6px 15px",
        whiteSpace: "nowrap",
        ...props.buttonStyle,
      }}
      {...props}
    >
      {props?.loading ?
        <CircularProgress
          sx={{
            color: Colors.white,
            width: "20px !important",
            height: "20px !important"
          }}
        /> :
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: "14px"
          }}
        >
          {props.title}
        </Typography>
      }
    </Button>
  )
};

export const PrimaryIconButton = (props) => {
  return (
    <IconButton
      sx={{
        background: Colors.buttonGradient
      }}
      {...props}
    >
      {
        props?.loading ?
          <CircularProgress
            sx={{
              color: Colors.white,
              width: "20px !important",
              height: "20px !important"
            }}
          /> :
          <Search sx={{ color: Colors.dark3 }} />
      }
    </IconButton>
  )
}

export const SecondaryButton = (props) => {
  return (
    <Button
      variant="outlined"
      sx={{
        ":hover": {
          background: Colors.dark1,
          borderColor: Colors.primary,
        },
        fontFamily: "Poppins",
        fontWeight: 600,
        textTransform: "capitalize",
        borderRadius: "15px",
        p: "6px 15px",
        background: Colors.dark1,
        borderColor: Colors.dark2,
        ...props.buttonStyle,
      }}
      {...props}
    >
      {props?.loading ?
        <CircularProgress
          sx={{
            color: Colors.secondary,
            width: "20px !important",
            height: "20px !important"
          }}
        /> :
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 900,
            background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        >
          {props.title}
        </Typography>
      }
    </Button>
  )
};

export const ToggleButton = ({ checked, onChange, label, width, disabled }) => {
  return (
    <FormControlLabel
      sx={{
        "&.MuiFormControlLabel-root": {
          m: 0,
          gap: 1
        }
      }}
      labelPlacement="start"
      control={
        <ToggleSwitch
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
      }
      label={
        <Typography
          sx={{
            color: Colors.white,
            width: width
          }}
        >
          {label}
        </Typography>
      }
    />
  )
};

export const EditButton = ({ onClick, isEdit }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        border: `1px solid ${Colors.primary}`,
        p: "4px",
        display: "flex",
        alignItems: "center",
        gap: 1,
        borderRadius: "16px",
        cursor: "pointer"
      }}
    >
      <Typography variant="caption"
        sx={{
          color: Colors.white
        }}
      >
        Edit
      </Typography>
      <Box
        size={"small"}
        sx={{
          borderRadius: "50%",
          width: "16px",
          height: "16px",
          background: Colors.buttonGradient,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        onClick={onClick}
      >
        {isEdit ? (
          <Close sx={{ color: Colors.white, width: "12px", height: "12px" }} />
        ) : (
          <Edit sx={{ color: Colors.white, width: "12px", height: "12px" }} />
        )}
      </Box>
    </Box>
  )
};