import { Box } from "@mui/material";
import Colors from "../../style";

export const PageDot = ({ top, left }) => {
  return (
    <Box
      component={"span"}
      sx={{
        position: "absolute",
        width: "3px",
        height: "13px",
        background: Colors.dashboardGradient,
        // left: "-24px",
        // top: "13px",
        left: { top },
        top: { left },
      }}
    />
  )
}