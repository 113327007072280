import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Box, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import BreadCrumb from '../../../components/breadcrumb';
import Colors from '../../../style';
import { InfoIcon, InstagramIcon, SnapchatIcon, TiktokIcon, UserIcon } from '../../../assets/icons';
import { Close, CloudUpload, Edit, MoreHoriz } from '@mui/icons-material';
import Images from '../../../assets/images';
import { useLocation, useParams } from 'react-router-dom';
import AdminServices from '../../../api/admin/AdminServices';
import { baseUrl } from '../../../../axios';
import { ErrorToaster, SuccessToaster } from '../../../components/toaster';
import { PageDot } from '../../../components/UI';
import { Capitalize } from '../../../utils/Capitalize';
import Chip from '../../../components/chip';
import { EditDialog } from '../../../components/dialog';
import { EditField, EditMultiSelect, EditSelectField, InputField, InputSelectField } from '../../../components/inputfields';
import { EditButton, PrimaryButton, SecondaryButton } from '../../../components/buttons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper/modules";
import 'swiper/css/bundle';
import 'swiper/css/pagination';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const genders = [{ id: 1, name: "Male" }, { id: 2, name: "Female" }];

function MemberDetail() {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const { id } = useParams();
  const [isActive, setIsActive] = useState(null);
  const [overallScore, setOverallScore] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [updatedImages, setUpdatedImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState([]);
  const { register, handleSubmit, setValue } = useForm();
  const inputRefs = useRef([]);

  const handleStatusApproved = async () => {
    try {
      const obj = {
        user_id: data?.user_id,
        is_approved: true,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.location.reload();
    }
  };

  const handleStatusDisapproved = async () => {
    try {
      const obj = {
        user_id: data?.user_id,
        is_approved: false,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.location.reload();
    }
  }

  const handleStatus = async (e) => {
    const obj = {
      is_active: e.target.checked,
    }
    try {
      const result = await AdminServices.updateUser(data?.user_id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message)
        setIsActive(result.data.updatedUser.is_active);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchData = async () => {
    try {
      const result = await AdminServices.getMemberById(id);
      if (result.responseCode === 200) {
        setData(result?.data?.members[0]);
        setIsActive(result?.data?.members[0].is_active);
        const { averageAppearance, averageFriendliness, averageOnTime } = result?.data?.members[0];
        if (averageAppearance != null && averageFriendliness != null && averageOnTime != null) {
          const score = averageAppearance + averageFriendliness + averageOnTime / 3;
          setOverallScore(score);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const setDefaultData = () => {
    setValue("f_name", data?.user_name);
    setValue("l_name", data?.last_name);
    setSelectedGender(() => {
      const selected = genders.find((item) => item.name == Capitalize(data?.gender))?.name;
      return selected;
    });
    setUpdatedImages(data?.pictures);
    setValue("insta", data?.insta);
    setValue("snap", data?.snap);
    setValue("tiktok", data?.tiktok)
  };

  const handleFileChange = async (file, ind) => {
    const formData = new FormData();
    formData.append("document", file);
    try {
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setUpdatedImages((prev) => {
          const updatedImagesCopy = [...prev];
          updatedImagesCopy[ind] = result?.data?.files[0]?.path; // Replace the image at the current index
          return updatedImagesCopy;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCategories = async () => {
    try {
      const result = await AdminServices.getAllCategories(1, 50, "");
      if (result.responseCode == 200) {
        const filtered = result?.data?.categories.map((item) => ({
          id: item._id,
          name: item.name,
          type: item.type
        }))
        setCategories(filtered);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const updateData = async (formData) => {
    const ids = selected.map((item) => item.id);
    const obj = {
      userProfile: {
        first_name: formData.f_name,
        last_name: formData.l_name,
      },
      memberProfile: {
        type: ids,
        gender: formData.gender,
        insta: formData.insta,
        snap: formData.snap,
        tiktok: formData.tiktok,
        pictures: updatedImages,
      }
    };
    try {
      const result = await AdminServices.updateMember(data?.user_id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setIsEdit(false);
      }
    } catch (err) {
      ErrorToaster(err);
    } finally {
      console.log("run");
      fetchData();
    }
  }

  useEffect(() => {
    let isMounted = true;

    fetchData();
    getAllCategories();

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    setDefaultData();
  }, [data]);

  return (data != null && (
    <Fragment>
      <EditDialog
        open={open}
        onClose={() => setOpen(false)}
        title={"Update Profile"}
        data={
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography varaint="h6">
                    Profile Image
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <CardMedia
                      component={"img"}
                      src={baseUrl + data.pictures[0]}
                      sx={{
                        m: "0 auto",
                        width: "156px",
                        height: "156px",
                        objectFit: "cover",
                        border: `2px solid ${Colors.white}`,
                        borderRadius: "50%"
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Typography varaint="h6">
                    Social Media Handle
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Instagram"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Snapchat"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"TikTok"}
                    />
                  </Box>
                </Grid>
              </Grid>

            </Grid>
            <Grid item md={6}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography varaint="h6">
                    Profile Details
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Name"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Email"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Phone"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Gender"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box>
                    <InputSelectField
                      fullWidth={true}
                      label={"Address"}
                    />
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 2
                    }}
                  >
                    <SecondaryButton
                      title={"Cancel"}
                      onClick={() => setOpen(false)}
                      buttonStyle={{ p: "16px 40px", width: "50%" }}
                    />
                    <PrimaryButton
                      title={"Save"}
                      buttonStyle={{ p: "16px 40px", width: "50%" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb
            pageName={"Member Detail"}
            status={{ data: data, state: state }}
            handleStatusApproved={handleStatusApproved}
            handleStatusDisapproved={handleStatusDisapproved}
            buttonProps={data?.is_approved ? true : false}
            isActive={isActive}
            onChange={handleStatus}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: 2
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(updateData)}
          sx={{
            background: Colors.dark1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "16px 24px 0px 24px",
              position: "relative"
            }}
          >
            <PageDot top={"30px"} left={0} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <UserIcon />
              <Typography
                sx={{
                  color: Colors.white,
                  fontWeight: 600,
                  fontSize: "18px"
                }}
              >
                Profile Information
              </Typography>
              <Tooltip title="Edit">
                <EditButton
                  onClick={handleClick}
                  isEdit={isEdit}
                />
              </Tooltip>
              {isEdit && (
                <SecondaryButton
                  type={"submit"}
                  title={"Update"}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: isEdit ? "360px" : "auto"
              }}
            >
              {isEdit ? (
                <EditMultiSelect
                  data={categories}
                  selected={selected}
                  setSelected={setSelected}
                  defaultTypes={data?.type}
                  componentOf={"member"}
                />
              ) : (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {data?.type?.map((item, ind) => (
                    <Chip key={ind} title={item} />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Grid
            container
            sx={{
              borderTop: `2px solid ${Colors.dark2}`
            }}
          >
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 3
                }}
              >
                <Grid container>
                  <Grid item md={12}>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      modules={[Pagination]}
                      pagination={{ clickable: true }}
                    >
                      {updatedImages?.map((item, ind) => (
                        <SwiperSlide key={item}>
                          <Box
                            sx={{
                              position: "relative",
                              "&:hover .overlay": {
                                opacity: isEdit ? 1 : 0, // Apply hover effect only when isEdit is true
                              },
                            }}
                          >
                            <CardMedia
                              component={"img"}
                              src={baseUrl + item}
                              sx={{
                                width: "100%",
                                height: "250px",
                                objectFit: "cover",
                                borderRadius: "15px"
                              }}
                            />
                            <Box
                              className="overlay"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: 0, // Default to hidden
                                transition: "opacity 0.3s ease",
                              }}
                            >
                              {isEdit && (
                                <label>
                                  <input
                                    ref={(input) => (inputRefs.current[ind] = input)}
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }} // Hide the input
                                    onChange={(e) => handleFileChange(e.target.files[0], ind)}
                                  />
                                  <IconButton
                                    onClick={() => inputRefs.current[ind].click()}
                                    sx={{
                                      border: `1px solid ${Colors.secondary}`
                                    }}
                                  >
                                    <CloudUpload sx={{ color: Colors.secondary }} />
                                  </IconButton>
                                </label>
                              )}
                            </Box>
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    {isEdit ? (
                      <Fragment>
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          First Name
                        </Typography>
                        <EditField
                          register={register("f_name")}
                        />
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          Last Name
                        </Typography>
                        <EditField
                          register={register("l_name")}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {`${data?.user_name} ${data?.last_name}`}
                        </Typography>
                      </Fragment>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.phone}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Gender
                    </Typography>
                    {isEdit ? (
                      <EditSelectField
                        select={true}
                        options={genders}
                        value={selectedGender}
                        register={register("gender", {
                          onChange: (e) => setSelectedGender(e.target.value),
                        })}
                      />
                    ) : (
                      <Typography
                        variant='body2'
                        sx={{
                          color: Colors.white
                        }}
                      >
                        {Capitalize(data?.gender)}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {`${data?.city}, ${data?.country}`}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      DOB - Age
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("dob")}
                      />
                    ) : (
                      <Typography
                        variant='body2'
                        sx={{
                          color: Colors.white
                        }}
                      >
                        {data?.email}
                      </Typography>
                    )}
                  </Box>
                </Box> */}
              </Box>
            </Grid>
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: "100%"
                }}
              >
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      About Me
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: Colors.white,
                      }}
                    >
                      {/* {state?.about_me} */}
                      {`My name is ${data?.user_name}, based in ${data?.city}, ${data?.country}. You can contact me at ${data?.phone} or via email at ${data?.email}. As an influencer registered on the Fanoos platform, I specialize in content creation across YouTube, Snapchat, TikTok, and other channels. Fanoos offers businesses an excellent opportunity to collaborate with me to effectively promote their products or projects to a targeted audience. Whether you're a startup, established organization, or local brand, partnering with me on Fanoos ensures strategic outreach and engagement with your desired demographic.`}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: 3
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: Colors.white,
                      fontWeight: 600
                    }}
                  >
                    Social Media Handle
                  </Typography>
                  {data?.insta != "" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        borderBottom: `1px solid ${Colors.dark2}`,
                      }}
                    >
                      <InstagramIcon />
                      <Box
                        sx={{
                          p: 1,
                          width: isEdit ? "100%" : "auto"
                        }}
                      >
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          Instagram
                        </Typography>
                        {isEdit ? (
                          <EditField
                            register={register("insta")}
                          />
                        ) : (
                          <Typography
                            variant='body2'
                            sx={{
                              color: Colors.white
                            }}
                          >
                            {data?.insta}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  {data?.snap != "" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        borderBottom: `1px solid ${Colors.dark2}`,
                      }}
                    >
                      <SnapchatIcon />
                      <Box
                        sx={{
                          p: 1,
                          width: isEdit ? "100%" : "auto"
                        }}
                      >
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          Snapchat
                        </Typography>
                        {isEdit ? (
                          <EditField
                            register={register("snap")}
                          />
                        ) : (
                          <Typography
                            variant='body2'
                            sx={{
                              color: Colors.white
                            }}
                          >
                            {data?.snap}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  {data?.tiktok != "" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <TiktokIcon />
                      <Box
                        sx={{
                          p: 1,
                          width: isEdit ? "100%" : "auto"
                        }}
                      >
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          Tiktok
                        </Typography>
                        {isEdit ? (
                          <EditField
                            register={register("tiktok")}
                          />
                        ) : (
                          <Typography
                            variant='body2'
                            sx={{
                              color: Colors.white
                            }}
                          >
                            {data?.tiktok}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={4.5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 3,
                  pt: 5,
                  px: 3,
                  pb: 3
                }}
              >
                <Box
                  sx={{
                    width: "180px",
                    height: "180px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    borderRadius: '50%',
                    boxShadow: `0 0 30px 0px ${Colors.primary + 47}, 0 0 50px 0px ${Colors.secondary + 75}`,
                    background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
                    mb: "18px",
                    "&::before": {
                      content: '""',
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      right: '10px',
                      bottom: '10px',
                      borderRadius: '50%',
                      backgroundColor: Colors.dark1,
                    }
                  }}
                >
                  <Typography
                    sx={{
                      position: 'relative',
                      color: 'white',
                      textAlign: 'center',
                      fontSize: "12px"
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "36px",
                        fontWeight: 700
                      }}
                      component="span"
                    >
                      {overallScore.toFixed(1)}
                    </Typography>
                    <br />
                    Overall Score
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: Colors.dark3,
                    borderRadius: "10px",
                    p: 2,
                    minWidth: "-webkit-fill-available"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2
                    }}
                  >
                    <Box
                      sx={{
                        width: "45px",
                        height: "45px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        borderRadius: '50%',
                        boxShadow: `0 0 10px 0px ${Colors.primary + 47}, 0 0 20px 0px ${Colors.secondary + 75}`,
                        background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
                        "&::before": {
                          content: '""',
                          position: 'absolute',
                          top: '3px',
                          left: '3px',
                          right: '3px',
                          bottom: '3px',
                          borderRadius: '50%',
                          backgroundColor: Colors.dark3
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          position: 'relative',
                          color: 'white',
                          textAlign: 'center',
                          fontSize: "16px"
                        }}
                      >
                        {data?.averageOnTime !== null ? data?.averageOnTime.toFixed(1) : 0}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: Colors.white }}>
                      On Time
                    </Typography>
                  </Box>
                  <InfoIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: Colors.dark3,
                    borderRadius: "10px",
                    p: 2,
                    minWidth: "-webkit-fill-available"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2
                    }}
                  >
                    <Box
                      sx={{
                        width: "45px",
                        height: "45px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        borderRadius: '50%',
                        boxShadow: `0 0 10px 0px ${Colors.primary + 47}, 0 0 20px 0px ${Colors.secondary + 75}`,
                        background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
                        "&::before": {
                          content: '""',
                          position: 'absolute',
                          top: '3px',
                          left: '3px',
                          right: '3px',
                          bottom: '3px',
                          borderRadius: '50%',
                          backgroundColor: Colors.dark3
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          position: 'relative',
                          color: 'white',
                          textAlign: 'center',
                          fontSize: "16px"
                        }}
                      >
                        {data?.averageAppearance !== null ? data?.averageAppearance.toFixed(1) : 0}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: Colors.white }}>
                      Appearance
                    </Typography>
                  </Box>
                  <InfoIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: Colors.dark3,
                    borderRadius: "10px",
                    p: 2,
                    minWidth: "-webkit-fill-available"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2
                    }}
                  >
                    <Box
                      sx={{
                        width: "45px",
                        height: "45px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        borderRadius: '50%',
                        boxShadow: `0 0 10px 0px ${Colors.primary + 47}, 0 0 20px 0px ${Colors.secondary + 75}`,
                        background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
                        "&::before": {
                          content: '""',
                          position: 'absolute',
                          top: '3px',
                          left: '3px',
                          right: '3px',
                          bottom: '3px',
                          borderRadius: '50%',
                          backgroundColor: Colors.dark3
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          position: 'relative',
                          color: 'white',
                          textAlign: 'center',
                          fontSize: "16px"
                        }}
                      >
                        {data?.averageFriendliness !== null ? data?.averageFriendliness.toFixed(1) : 0}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: Colors.white }}>
                      Friendliness
                    </Typography>
                  </Box>
                  <InfoIcon />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  ))
}

export default MemberDetail;