import { Box, Typography } from "@mui/material";
import Colors from "../../style";

function Chip({ title }) {
  return (
    <Box
      sx={{
        background: Colors.dark3,
        borderRadius: "18px",
        p: "8px 24px"
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          color: "transparent",
          background: `linear-gradient(to right, ${Colors.primary}, ${Colors.secondary})`,
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default Chip;