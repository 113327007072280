import { Autocomplete, Box, Button, Checkbox, Chip, List, MenuItem, TextField } from "@mui/material";
import Colors from "../../style";
import styled from "@emotion/styled";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ArrowDropDown } from "@mui/icons-material";
import { Fragment, useEffect } from "react";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent', // Remove the default border
    },
    '&:hover fieldset': {
      borderColor: 'transparent', // Remove the border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent', // Remove the border on focus
    },
    color: Colors.white,
  },
  '& input': {
    padding: '10px 14px',
    '::placeholder': {
      color: Colors.white, // Set the placeholder color to white
      opacity: 1, // Ensure the color is not transparent
    }
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Nunito Sans',
    color: Colors.white,
    fontSize: '14px',
  },
}));

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: "2.5px !important",
    '& fieldset': {
      borderColor: 'transparent', // Remove the default border
    },
    '&:hover fieldset': {
      borderColor: 'transparent', // Remove the border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent', // Remove the border on focus
    },
    color: Colors.white,
  },
  '& input': {
    '::placeholder': {
      color: Colors.white, // Set the placeholder color to white
      opacity: 1, // Ensure the color is not transparent
    }
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Nunito Sans',
    color: Colors.white,
    fontSize: '14px',
  },
}));

export const InputSelectField = ({
  select,
  type,
  variant,
  fullWidth,
  label,
  multiline,
  rows,
  data,
  register,
  error,
  helperText,
  value,
  inputProps,
  onChange,
  InputProps,
  disabled,
  dir
}) => {
  return (
    <TextField
      dir={dir}
      disabled={disabled}
      value={value}
      select={select}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      label={label}
      multiline={multiline}
      rows={rows}
      error={error}
      helperText={helperText}
      onChange={onChange}
      inputProps={inputProps}
      {...register}
      FormHelperTextProps={{
        sx: {
          color: Colors.white
        }
      }}
      InputLabelProps={{
        shrink: Boolean(value) || undefined,
        sx: {
          color: Colors.white,
          "&.Mui-focused": {
            color: `${Colors.white} !important`
          },
          "&.Mui-disabled": {
            color: `${Colors.white + 30} !important`
          }
        },
      }}
      InputProps={{
        ...InputProps,
        sx: {
          color: Colors.white,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.white,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.white,
            borderWidth: "1px"
          },

          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.white + 30,
            color: Colors.white + 30,
            borderWidth: "1px",
          },
        }
      }}
      sx={{
        opacity: 1,
        "& fieldset": {
          borderColor: Colors.white,
          borderRadius: "15px",
        },
        "svg": {
          color: Colors.white
        },
      }}
      SelectProps={{
        MenuProps: {
          slotProps: {
            paper: {
              sx: {
                background: Colors.dark3,
                color: Colors.white,
                borderRadius: "15px"
              }
            }
          }
        }
      }}
    >
      {data && data.map((opt, i) => (
        <MenuItem
          sx={{
            ":hover": {
              background: Colors.buttonGradient
            },
          }}
          key={i}
          value={opt.id ? opt.id : opt}
        >
          {opt.name ? opt.name : opt}
        </MenuItem>
      ))}
    </TextField>
  )
};

export const InputField = ({
  value,
  fullWidth,
  select,
  onChange,
  options,
  placeholder,
  displayEmpty,
  type,
  disabled
}) => {
  return (
    <CustomTextField
      disabled={disabled}
      displayEmpty={displayEmpty}
      placeholder={placeholder}
      type={type}
      value={value}
      fullWidth={fullWidth}
      select={select}
      size={"small"}
      sx={{
        "svg": {
          color: Colors.white
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: Colors.white, // Ensures the text color stays white
          opacity: 0.5 // Ensures that the placeholder is visible when disabled
        }
      }}
      onChange={onChange}
      SelectProps={{
        displayEmpty: true,
        MenuProps: {
          slotProps: {
            paper: {
              sx: {
                background: Colors.dark3,
                color: Colors.white,
              }
            }
          }
        }
      }}
    >
      <MenuItem
        value=""
        disabled
      >
        {placeholder}
      </MenuItem>
      {options && options.map((item, ind) => (
        <MenuItem
          key={ind}
          value={item.id ? item.id : item}
          sx={{
            ":hover": {
              background: Colors.buttonGradient
            },
          }}
        >
          {item.name ? item.name : item}
        </MenuItem>
      ))}
    </CustomTextField>
  )
};

export const AutoCompleteField = ({
  value,
  fullWidth,
  onChange,
  options,
  placeholder,
  disabled
}) => {
  return (
    <CustomAutocomplete
      disableClearable={true}
      disabled={disabled}
      value={value}
      fullWidth={fullWidth}
      options={options}
      onChange={onChange}
      onKeyDown={(e) => {
        if (e.key == "enter") {
          onChange();
        }
      }}
      getOptionLabel={(option) => option.name ? option.name : option}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          sx={{
            "svg": {
              color: Colors.white
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: Colors.white, // Ensures the text color stays white
              opacity: 0.5 // Ensures that the placeholder is visible when disabled
            }
          }}
        />
      )}
      sx={{
        "& .MuiAutocomplete-popupIndicator": {
          color: Colors.white,
        },
        "& .MuiAutocomplete-clearIndicator": {
          color: Colors.white,
        },
        "& .MuiAutocomplete-listbox": {
          background: Colors.dark3,
          color: Colors.white,
        },
        "& .MuiAutocomplete-option:hover": {
          background: Colors.buttonGradient,
        },
      }}
      slotProps={{
        paper: {
          sx: {
            background: Colors.dark3,
            color: Colors.white
          }
        },
      }}
      ListboxProps={{
        sx: {
          "li": {
            ":hover": {
              background: Colors.buttonGradient
            }
          }
        }
      }}
    />
  );
};

export const DateField = ({
  placeholder,
  value,
  onChange
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        width: "100%"
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={value}
          onChange={onChange}
          sx={{
            "&.MuiFormControl-root": {
              width: "100%",
              "& .MuiIconButton-root": {
                color: Colors.white, // Ensure this targets the IconButton
              },
            }
          }}
          slots={{
            openPickerIcon: ArrowDropDown,
            textField: CustomTextField,
          }}
          slotProps={{
            textField: {
              size: "small",
              placeholder: placeholder
            },
            desktopPaper: {
              sx: {
                background: Colors.dark3,
                color: Colors.white,
                "& .MuiTypography-root": {
                  color: Colors.white
                },
                "& .MuiButtonBase-root": {
                  color: Colors.white
                }
              }
            }
          }}
        />
      </LocalizationProvider>
    </Box>
  )
};

export const EditField = ({ register, onClick }) => {
  return (
    <TextField
      fullWidth
      size={"small"}
      variant={"standard"}
      {...register}
      onClick={onClick}
      InputLabelProps={{
        sx: {
          "&.Mui-focused": {
            color: Colors.white,
          },
          color: Colors.white
        },
      }}
      sx={{
        "input": {
          color: Colors.white,
          fontSize: "10px",
        },
        "& .MuiInput-root:before": {
          borderColor: Colors.primary,
          borderWidth: "2px",
        },
        "& .MuiInput-root:after": {
          borderColor: Colors.primary,
          borderWidth: "2px",
        },
        "& .MuiInput-root:hover:not(.Mui-disabled):before": {
          borderColor: Colors.secondary,
          borderWidth: "2px",
        },
      }}
    />
  )
};

export const EditSelectField = ({ register, onClick, select, options, value }) => {
  return (
    <TextField
      fullWidth
      select={select}
      value={value}
      size={"small"}
      variant={"standard"}
      {...register}
      onClick={onClick}
      InputLabelProps={{
        sx: {
          "&.Mui-focused": {
            color: Colors.white,
          },
          color: Colors.white
        },
      }}
      sx={{
        ".MuiInputBase-root": {
          color: Colors.white,
          fontSize: "10px",
          "svg": {
            color: Colors.white
          }
        },
        "& .MuiInput-root:before": {
          borderColor: Colors.primary,
          borderWidth: "2px",
        },
        "& .MuiInput-root:after": {
          borderColor: Colors.primary,
          borderWidth: "2px",
        },
        "& .MuiInput-root:hover:not(.Mui-disabled):before": {
          borderColor: Colors.secondary,
          borderWidth: "2px",
        },
      }}
      SelectProps={{
        MenuProps: {
          slotProps: {
            paper: {
              sx: {
                background: Colors.dark3,
                "& .MuiMenuItem-root": {
                  color: Colors.white,
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  background: Colors.buttonGradient,
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: Colors.primary,
                },
              }
            }
          }
        }
      }}
    >
      {options && options.map((item) => (
        <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
      ))}
    </TextField>
  )
};

export const EditDateField = ({ value, onChange, placeholder }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={value}
        onChange={onChange}
        slots={{
          openPickerIcon: ArrowDropDown,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            placeholder: placeholder,
            variant: "standard",
            sx: {
              "input": {
                color: Colors.white,
                fontSize: "10px",
              },
              "& .MuiInput-root": {
                ":hover": {
                  "&:before": {
                    borderColor: Colors.secondary,
                    borderWidth: "2px",
                  },
                  "&:after": {
                    borderColor: Colors.secondary,
                    borderWidth: "2px",
                  }
                },
                "&:before": {
                  borderColor: Colors.primary,
                  borderWidth: "2px",
                },
                "&:after": {
                  borderColor: Colors.primary,
                  borderWidth: "2px",
                }
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: Colors.white, // Change this to your desired color
              }
            }
          },
          desktopPaper: {
            sx: {
              background: Colors.dark3,
              color: Colors.white,
              "& .MuiTypography-root": {
                color: Colors.white
              },
              "& .MuiButtonBase-root": {
                color: Colors.white
              }
            }
          }
        }}
      />
    </LocalizationProvider>
  )
};

export const EditTimeField = ({ label, value, onChange, register }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        {...register}
        label={label}
        value={value}
        onChange={onChange}
        slots={{
          openPickerIcon: ArrowDropDown,
        }}
        slotProps={{
          textField: {
            size: "small",
            variant: "standard",
            sx: {
              m: 0,
              "input": {
                color: Colors.white,
                fontSize: "10px",
              },
              "& .MuiInput-root:before": {
                borderColor: Colors.primary,
                borderWidth: "2px",
              },
              "& .MuiInput-root:after": {
                borderColor: Colors.primary,
                borderWidth: "2px",
              },
              "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                borderColor: Colors.secondary,
                borderWidth: "2px",
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: Colors.white, // Change this to your desired color
              }
            }
          },
          desktopPaper: {
            sx: {
              background: Colors.dark3,
              color: Colors.white,
              "& .Mui-selected": {
                background: Colors.primary
              },
              "& .MuiButton-root": {
                color: Colors.primary
              }
            }
          }
        }}
      />
    </LocalizationProvider>
  )
};

export const EditMultiSelect = ({ data, selected, setSelected, defaultTypes, onChange, componentOf }) => {
  const filteredData = data?.filter((item) => item.type != "Business" && item.type != "Events & Offers");

  useEffect(() => {
    if (data.length > 0) {
      if (componentOf == "event") {
        const ids = defaultTypes?.map(item => (item._id));
        const defaultSelected = data.filter((item) => ids.includes(item.id));
        setSelected(defaultSelected);
      }
      if (componentOf == "member") {
        const defaultSelected = data.filter((item) => defaultTypes.includes(item.name));
        setSelected(defaultSelected);
      }
    }
  }, [data]);

  const handleToggleItem = (item) => {
    if (item != null && item.id) {
      setSelected((prevSelected) =>
        prevSelected.some(selectedItem => selectedItem?.id === item.id)
          ? prevSelected.filter(selectedItem => selectedItem?.id !== item.id)
          : [...prevSelected, item]
      );
    }
  };

  return (
    <Autocomplete
      size={"small"}
      fullWidth
      multiple
      onChange={onChange}
      options={filteredData}
      value={selected}
      disableClearable
      disableCloseOnSelect
      id="multi-select-autocomplete"
      getOptionLabel={(option) => option.name}
      slotProps={{
        paper: {
          sx: {
            background: Colors.dark3,
            color: Colors.white,
            borderRadius: '15px',
          }
        }
      }}
      renderOption={(props, option, { selected }) => (
        <Fragment>
          <MenuItem {...props} value={selected} onClick={() => handleToggleItem(option)}>
            <Checkbox
              checked={selected}
              sx={{
                color: `${Colors.primary} !important`,
              }}
            />
            {option.name}
          </MenuItem>
        </Fragment>
      )}
      renderTags={(selected) => {
        const chipsToShow = selected.length > 3 ? selected.slice(0, 3) : selected;
        const extraCount = selected.length - 3;
        return (
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            {chipsToShow.map((option, index) => (
              <Chip
                size={"small"}
                key={option.id}
                label={option.name}
                sx={{
                  background: Colors.primary,
                  color: Colors.white,
                }}
              />
            ))}
            {selected.length > 3 && (
              <Chip
                size={"small"}
                label={`+${extraCount}`}
                sx={{
                  background: Colors.primary,
                  color: Colors.white,
                }}
              />
            )}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Select Types"}
          variant="outlined"
          InputLabelProps={{
            sx: {
              color: Colors.primary,
              "&.Mui-focused": {
                color: Colors.primary,
              },
            },
          }}
          sx={{
            "input": {
              color: Colors.white,
            },
            "& .MuiAutocomplete-root": {
              borderColor: Colors.white,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.primary,
              borderRadius: "15px",
              color: Colors.white,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.primary,
              borderWidth: '1px',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${Colors.primary} !important`,
              borderWidth: '1px !important',
            },
            "&.Mui-focused": {
              "fieldset": {
                borderColor: `${Colors.primary} !important`,
                borderWidth: '1px !important',
              }
            },
            ":hover": {
              "fieldset": {
                borderColor: `${Colors.primary} !important`,
                borderWidth: '1px !important',
              }
            },
            'svg': {
              color: Colors.primary,
            },
          }}
        />
      )}
      ListboxComponent={(listboxProps) => (
        <List component={"ul"} {...listboxProps}>
          <MenuItem>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setSelected(() => {
                  const filtered = data?.filter((item) => item.type != "Business" && item.type != "Events & Offers");
                  return filtered;
                });
              }}
              sx={{
                borderColor: Colors.primary,
                color: Colors.primary,
                textTransform: "capitalize",
                marginRight: 1,
                ":hover": {
                  borderColor: Colors.secondary,
                  color: Colors.secondary,
                }
              }}
            >
              Select All
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setSelected([]);
              }}
              sx={{
                borderColor: Colors.primary,
                color: Colors.primary,
                textTransform: "capitalize",
                ":hover": {
                  borderColor: Colors.secondary,
                  color: Colors.secondary,
                }
              }}
            >
              Unselect All
            </Button>
          </MenuItem>
          {listboxProps.children}
        </List>
      )}
    />
  )
};