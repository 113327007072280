const Images = {
  logo: require("./logo.png"),
  login: require("./login.png"),
  profileImage: require("./profileImage.png"),
  businessImage1: require("./Image (10).png"),
  businessImage2: require("./Image (11).png"),
  businessImage3: require("./Image (12).png"),
  businessImage4: require("./Image (13).png"),
  businessLogo: require("./Image (16).png"),
  defaultUser: require("./defaultuser.png"),
}

export default Images;