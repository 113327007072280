import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Box, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import BreadCrumb from '../../../../components/breadcrumb';
import Colors from '../../../../style';
import { CalendarIcon, ClockIcon, MarkerIcon, MemberIcon2, UserIcon } from '../../../../assets/icons';
import { Close, CloudUpload, Edit } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import AdminServices from '../../../../api/admin/AdminServices';
import { baseUrl } from '../../../../../axios';
import { ErrorToaster, SuccessToaster } from '../../../../components/toaster';
import { PageDot } from '../../../../components/UI';
import CustomChip from '../../../../components/chip';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { EditDateField, EditField, EditMultiSelect, EditSelectField, EditTimeField } from '../../../../components/inputfields';
import { EditButton, SecondaryButton } from '../../../../components/buttons';

function EventDetail() {
  const [eventData, setEventData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [updatedStartTime, setUpdatedStartTime] = useState(null);
  const [updatedEndTime, setUpdatedEndTime] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [updatedImages, setUpdatedImages] = useState([]);
  const [selected, setSelected] = useState([]);
  const startTime = moment(eventData?.start_time, "HH:mm");
  const endTime = moment(eventData?.end_time, "HH:mm");
  const duration = moment.duration(endTime.diff(startTime));
  const hours = duration.asHours();
  const { register, setValue, handleSubmit } = useForm();
  const inputRefs = useRef([]);
  const { state } = useLocation();
  const { id } = useParams();

  const getEventDetails = async () => {
    try {
      const result = await AdminServices.getEventDetails(id);
      if (result?.responseCode == 200) {
        setEventData(result?.data?.events[0]);
      }
    } catch (err) {
      ErrorToaster(err);
    }
  };

  const handleStatusApproved = async () => {
    try {
      const obj = {
        event_id: eventData?._id,
        is_approved: true,
      };
      const result = await AdminServices.approveEvent(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
        const obj = {
          event_id: eventData?._id,
        }
        try {
          const inviteResult = await AdminServices.sendInvite(obj);
          if (inviteResult.responseCode == 200) {
            SuccessToaster(inviteResult?.message);
          }
        } catch (err) {
          console.log(err);
        }
      }
    } catch (e) {
      console.log(e);
    };
  };

  const handleStatusDisapproved = async () => {
    try {
      const obj = {
        event_id: eventData?._id,
        is_approved: false,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    };
  };

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const handleFileChange = async (file, ind) => {
    const formData = new FormData();
    formData.append("document", file);
    try {
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setUpdatedImages((prev) => {
          const updatedImagesCopy = [...prev];
          updatedImagesCopy[ind] = result?.data?.files[0]?.path; // Replace the image at the current index
          return updatedImagesCopy;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setDefaultValues = () => {
    setValue("name", eventData?.name);
    setValue("description", eventData?.description);
    setValue("rules", eventData?.rule);
    setValue("proposed_offer", eventData?.offer_member);
    setUpdatedDate(moment(eventData?.date));
    setUpdatedStartTime(moment(eventData?.start_time, "HH:mm"));
    setUpdatedEndTime(moment(eventData?.end_time, "HH:mm"));
    setValue("males", eventData?.no_member_male);
    setValue("females", eventData?.no_member_female);
    setSelectedCategory(eventData?.event_type[0]?.name || "-");
    setUpdatedImages(eventData?.image);
  };

  const getAllCategories = async () => {
    try {
      const result = await AdminServices.getAllCategories(1, 50, "");
      if (result.responseCode == 200) {
        const filtered = result?.data?.categories.map((item) => ({
          id: item._id,
          name: item.name,
          type: item.type
        }))
        setCategories(filtered);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const updateData = async (formData) => {
    console.log("🚀 ~ updateData ~ formData:", formData)
    const selectedTypesIds = selected.map((item) => item.id);
    const event_id = categories.find((item) => item.name == selectedCategory)?.id;
    const obj = {
      _id: id,
      name: formData.name,
      date: new Date(updatedDate),
      start_time: moment(updatedStartTime).format("HH:mm"),
      end_time: moment(updatedEndTime).format("HH:mm"),
      image: updatedImages,
      description: formData.description,
      rules: formData.rules,
      offer_member: formData.proposed_offer,
      type: selectedTypesIds,
      event_type: event_id,
      no_member_female: formData.females,
      no_member_male: formData?.males
    }
    console.log("🚀 ~ updateData ~ obj:", obj)
    try {
      const result = await AdminServices.updateEvent(obj);
      if (result?.responseCode == 200) {
        SuccessToaster(result?.message);
        setIsEdit(false);
      }
    } catch (err) {
      ErrorToaster(err)
    } finally {
      getEventDetails();
    }
  };

  const openMap = () => {
    const url = `https://www.google.com/maps?q=${eventData?.establishment?.latitude},${eventData?.establishment?.longitude}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    getEventDetails();
    getAllCategories();
  }, [id]);

  useEffect(() => {
    setDefaultValues();
  }, [eventData]);

  return (
    <Fragment>
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb
            status={state}
            disabled={isEdit}
            pageName={"Event Detail"}
            handleStatusApproved={handleStatusApproved}
            handleStatusDisapproved={handleStatusDisapproved}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: 2
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(updateData)}
          sx={{
            background: Colors.dark1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "16px 24px 0px 24px",
              position: "relative"
            }}
          >
            <PageDot top={"30px"} left={0} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <UserIcon />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center"
                }}
              >
                <Typography
                  sx={{
                    color: Colors.white,
                    fontWeight: 600,
                    fontSize: "18px"
                  }}
                >
                  Event Detail
                </Typography>
                {!eventData?.is_approved && (
                  <Fragment>
                    <Tooltip title="Edit">
                      <EditButton
                        onClick={handleClick}
                        isEdit={isEdit}
                      />
                    </Tooltip>
                    {isEdit && (
                      <SecondaryButton
                        type={"submit"}
                        title={"Update"}
                      />
                    )}
                  </Fragment>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: isEdit ? "360px" : "auto"
              }}
            >
              {isEdit ? (
                <EditMultiSelect
                  selected={selected}
                  setSelected={setSelected}
                  data={categories}
                  defaultTypes={eventData?.type}
                  componentOf={"event"}
                />
              ) : (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {eventData?.type?.map((item, ind) => (
                    <CustomChip key={ind} title={item.name} />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Grid
            container
            sx={{
              borderTop: `2px solid ${Colors.dark2}`
            }}
          >
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 3
                }}
              >
                <CardMedia
                  component={"img"}
                  src={baseUrl + eventData?.business?.company_logo}
                  // src={Images.businessLogo}
                  sx={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "15px"
                  }}
                />
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Business Name
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {eventData?.business?.company_name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {eventData?.user?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {eventData?.user?.phone}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: "100%"
                }}
              >
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Event Name
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("name")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {eventData?.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Event Description
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("description")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {eventData?.description}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Event Rules
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("rules")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {eventData?.rule}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Proposed Members
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("proposed_offer")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {eventData?.offer_member}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `2px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: Colors.white,
                        fontWeight: 600
                      }}
                    >
                      Event Type
                    </Typography>
                    {isEdit ? (
                      <EditSelectField
                        select={true}
                        value={selectedCategory}
                        register={register("event_type", {
                          onChange: (e) => {
                            const value = e.target.value;
                            console.log("🚀 ~ EventDetail ~ value:", value)
                            setSelectedCategory(value);
                          }
                        })}
                        options={categories.filter((item) => item.type == "Events & Offers")}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: Colors.white,
                        }}
                      >
                        {eventData?.event_type?.map(item => item.name)?.join(", ")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: "20px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      borderBottom: `1px solid ${Colors.dark2}`,
                    }}
                  >
                    <Box
                      sx={{
                        width: isEdit ? "10%" : "30px",
                        height: "30px",
                        borderRadius: "50%",
                        background: isEdit ? "transparent" : Colors.dark3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarIcon />
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        width: isEdit ? "90%" : "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.dark2,
                          fontSize: "10px"
                        }}
                      >
                        Date
                      </Typography>
                      {isEdit ? (
                        <EditDateField
                          value={updatedDate}
                          onChange={(e) => setUpdatedDate(e)}
                        />
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {moment(eventData?.date).format("DD MMM YYYY")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      borderBottom: `1px solid ${Colors.dark2}`,
                    }}
                  >
                    <Box
                      sx={{
                        width: isEdit ? "10%" : "30px",
                        height: "30px",
                        borderRadius: "50%",
                        background: isEdit ? "transparent" : Colors.dark3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ClockIcon />
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        width: isEdit ? "90%" : "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.dark2,
                          fontSize: "10px"
                        }}
                      >
                        Time
                      </Typography>
                      {isEdit ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1
                          }}
                        >
                          <EditTimeField
                            value={updatedStartTime}
                          />
                          <EditTimeField
                            value={updatedEndTime}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {`
                            ${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")} - ${hours.toFixed(2)} ${hours > 1 ? "Hrs" : "Hr"}
                          `}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {eventData?.establishment?.type != "online" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        borderBottom: `1px solid ${Colors.dark2}`,
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          background: Colors.dark3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MarkerIcon />
                      </Box>
                      <Box
                        sx={{
                          py: 1,
                        }}
                        onClick={openMap}
                      >
                        <Typography
                          sx={{
                            color: Colors.dark2,
                            fontSize: "10px"
                          }}
                        >
                          {"Location"}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white,
                            width: "235px"
                          }}
                        >
                          {`${eventData?.establishment?.address_1} ${eventData?.establishment?.address_2}`}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: isEdit ? "10%" : "30px",
                        height: "30px",
                        borderRadius: "50%",
                        background: isEdit ? "transparent" : Colors.dark3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MemberIcon2 />
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        width: isEdit ? "90%" : "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.dark2,
                          fontSize: "10px"
                        }}
                      >
                        Members
                      </Typography>
                      {isEdit ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1
                          }}
                        >
                          <EditField
                            register={register("males")}
                          />
                          <EditField
                            register={register("females")}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant='body2'
                          sx={{
                            color: Colors.white
                          }}
                        >
                          {`${eventData?.no_member_male} ${eventData?.no_member_male > 1 ? "Males" : "Male"} - ${eventData?.no_member_female} ${eventData?.no_member_female > 1 ? "Females" : "Female"}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4.25}>
              <Grid container sx={{ p: 2, rowGap: 2 }} columnSpacing={2}>
                {updatedImages?.map((item, ind) => (
                  <Grid
                    key={ind}
                    item
                    md={ind === 0 ? 12 : 6}
                    sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        "&:hover .overlay": {
                          opacity: isEdit ? 1 : 0, // Apply hover effect only when isEdit is true
                        },
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        src={baseUrl + item}
                        sx={{
                          width: "100%",
                          height: ind === 0 ? "250px" : "150px",
                          objectFit: "cover",
                          borderRadius: "15px",
                        }}
                      />
                      <Box
                        className="overlay"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          borderRadius: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          opacity: 0, // Default to hidden
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        {isEdit && (
                          <label>
                            <input
                              ref={(input) => (inputRefs.current[ind] = input)}
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }} // Hide the input
                              onChange={(e) => handleFileChange(e.target.files[0], ind)}
                            />
                            <IconButton
                              onClick={() => inputRefs.current[ind].click()}
                              sx={{
                                border: `1px solid ${Colors.secondary}`
                              }}
                            >
                              <CloudUpload sx={{ color: Colors.secondary }} />
                            </IconButton>
                          </label>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  )
}

export default EventDetail;