import React, { Fragment, useState } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Collapse,
  Button,
  Typography
} from '@mui/material';
import Navigation from "../../../Navigation";
import SubNavigation from '../../../SubNavigation';
import Colors from '../../style';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogoutIcon } from '../../assets/icons';
import useAuth from '../../hooks/useAuth';

function SideNav({ open }) {
  const [expand, setExpand] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userLogout, role } = useAuth();
  const handleToggleSubMenu = (index, data) => {
    let name = data.name;
    setExpand((prevExpand) => ({
      [name]: !prevExpand[name]
    }));
  };

  const handleLogout = () => {
    userLogout();
    navigate("/login");
  }

  const drawerWidth = 290;

  return (
    <Drawer
      open={open}
      variant="persistent"
      sx={{
        width: open == false ? 0 : drawerWidth,
        transition: "all .3s ease-in-out !important",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: open == false ? 0 : drawerWidth,
          boxSizing: 'border-box',
          background: Colors.dashboardGradient,
          transition: "all .3s ease-in-out !important",
          border: "none"
        },
      }}
    >
      <Toolbar
        sx={{
          minHeight: "72px !important",
          py: 1
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 6
        }}
      >
        <List
          sx={{
            pt: 3
          }}
        >
          {role == "admin" ? Navigation.map((item, index) => item.subMenu ? (
            <Fragment key={index}>
              <ListItem
                sx={{
                  px: 4,
                  color: item.subMenu.some((x) => x.path == pathname) ? Colors.black : Colors.white,
                  "svg": {
                    "path": {
                      fill: item.subMenu.some((x) => x.path == pathname) ? Colors.black : Colors.white,
                    }
                  }
                }}
                onClick={() => {
                  handleToggleSubMenu(index, item);
                }}
              >
                <ListItemButton
                  sx={{
                    ":hover": {
                      backgroundColor: "transparent",
                      color: Colors.black,
                      "svg": {
                        "path": {
                          fill: Colors.black
                        }
                      }
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "40px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  {expand[item.name]
                    ? <ExpandLess sx={{ color: Colors.white }} />
                    : <ExpandMore sx={{ color: Colors.white }} />
                  }
                </ListItemButton>
              </ListItem>
              <Collapse in={expand[item.name]}>
                <List sx={{ pl: 3, pt: 0, }}>
                  {item.subMenu.map((subItem, subInd) => (
                    <ListItemButton
                      onClick={() => navigate(subItem.path)}
                      key={subInd}
                      sx={{
                        pl: 8,
                        py: 0,
                        color: pathname == subItem.path ? Colors.black : Colors.white,
                        ":hover": {
                          backgroundColor: "transparent",
                          color: Colors.black
                        },
                      }}
                    >
                      <ListItemText primary={subItem.name}

                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ) : (
            <ListItem
              key={index}
              sx={{
                px: 4,
                color: pathname == item.path ? Colors.black : Colors.white,
                "svg": {
                  "path": {
                    fill: pathname == item.path ? Colors.black : Colors.white,
                  }
                }
              }}
            >
              <ListItemButton
                onClick={() => navigate(item.path)}
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                    color: Colors.black,
                    "svg": {
                      "path": {
                        fill: Colors.black
                      }
                    }
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "40px"
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          )) : role == "sub_admin" ? SubNavigation.map((item, index) => item.subMenu ? (
            <Fragment key={index}>
              <ListItem
                sx={{
                  px: 4,
                  color: item.subMenu.some((x) => x.path == pathname) ? Colors.black : Colors.white,
                  "svg": {
                    "path": {
                      fill: item.subMenu.some((x) => x.path == pathname) ? Colors.black : Colors.white,
                    }
                  }
                }}
                onClick={() => {
                  handleToggleSubMenu(index, item);
                }}
              >
                <ListItemButton
                  sx={{
                    ":hover": {
                      backgroundColor: "transparent",
                      color: Colors.black,
                      "svg": {
                        "path": {
                          fill: Colors.black
                        }
                      }
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "40px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  {expand[item.name]
                    ? <ExpandLess sx={{ color: Colors.white }} />
                    : <ExpandMore sx={{ color: Colors.white }} />
                  }
                </ListItemButton>
              </ListItem>
              <Collapse in={expand[item.name]}>
                <List sx={{ pl: 3, pt: 0, }}>
                  {item.subMenu.map((subItem, subInd) => (
                    <ListItemButton
                      onClick={() => navigate(subItem.path)}
                      key={subInd}
                      sx={{
                        pl: 8,
                        py: 0,
                        color: pathname == subItem.path ? Colors.black : Colors.white,
                        ":hover": {
                          backgroundColor: "transparent",
                          color: Colors.black
                        },
                      }}
                    >
                      <ListItemText primary={subItem.name}

                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ) : (
            <ListItem
              key={index}
              sx={{
                px: 4,
                color: pathname == item.path ? Colors.black : Colors.white,
                "svg": {
                  "path": {
                    fill: pathname == item.path ? Colors.black : Colors.white,
                  }
                }
              }}
            >
              <ListItemButton
                onClick={() => navigate(item.path)}
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                    color: Colors.black,
                    "svg": {
                      "path": {
                        fill: Colors.black
                      }
                    }
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "40px"
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          )) : <></>}
        </List>
        <Button
          sx={{
            textTransform: "capitalize",
            gap: 2
          }}
          onClick={() => handleLogout()}
        >
          <LogoutIcon />
          <Typography
            sx={{
              color: Colors.white
            }}
          >
            Logout
          </Typography>
        </Button>
      </Box>
    </Drawer>
  )
}

export default SideNav;