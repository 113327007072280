const AdminRoutes = {
  getAllMembers: "/admin/getMembers",
  getMemberById: "/admin/getMemberDetails",
  getAllBusiness: "/admin/getBusiness",
  getAllBrands: "/event/getBrands",
  getAllOffers: "/event/getOfferWEB",
  getOfferDetails: "/event/offerDetails",
  getAllInvites: "/event/getEventInvites",
  getAllEvents: "/event/getEventsWEB",
  getEventDetails: "/event/getEventDetails",
  updateStatus: "/admin/userStatus",
  approveEvent: "/event/approveEvent",
  approveOffer: "/event/approveOffer",
  sendInvite: "/event/sendEventInvites",
  getInvitedMembers: "/event/member_details_events",
  login: "/auth/admin/login",
  getBusinessById: "/admin/getInfo",
  createCategory: "/admin/createCategory",
  getAllCategories: "/admin/getAllCategories",
  updateCategory: "/admin/updateCategory",
  deleteCategory: "/admin/deleteCategory",
  getStats: "/admin/getStats",
  updateUser: "/auth/updateUserProfile",
  getNotifications: "/notifications/getNoti",
  getNotificationsCount: "/notifications/getCount",
  sendNotifications: "/admin/sendCustomNotification",
  getCustomNotifications: "/admin/getAllCustomNotifications",
  uploadImage: "/system/upload",
  updateImages: "/system/updateImagePaths",
  getImages: "/system/getImagePaths",
  getCountries: "/geo/countries",
  getCities: "/geo/cities",
  createSubAdmin: "/auth/sub_admin",
  getSubAdmins: "/auth/getSubAdmins",
  updateSubAdmin: "/auth/updateSubAdmin",
  approveSubscription: "/event/updateSubscriptionRequest",
  startSubscription: "/event/startMembership",
  getMembershipDetails: "/event/getMembershipDetails",
  getAllMemberships: "/event/getPendingRequests",
  getAvailedOffers: "/event/member_details_offers",
  updateEvent: "/event/updateEvent",
  updateOffer: "/event/updateOffer",
  updateMember: "/auth/updateUserProfile",
  updateBrand: "/event/updateBrands",
  updateBranch: "/event/updateEstablishment",
}

export default AdminRoutes;