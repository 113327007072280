import { Typography } from "@mui/material";
import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis
} from "recharts";

const useStyles = createUseStyles(() => ({
  container: {
    color: "#fff",
    backgroundColor: "transparent",
    transition: "0.3s ease-in-out",
    width: "100%",
    height: "275px",
  }
}));

const GradientColors = () => {
  return (
    <Fragment>
      <linearGradient id="colorView" x1="0" y1="0" x2="0" y2="1">
        <stop offset="30%" stopColor="#ec991c" stopOpacity={0.4} />
        <stop offset="75%" stopColor="#ffa3006b" />
        === ADD MORE COLOURS HERE ===
        <stop offset="95%" stopColor="#ffa30040" />
      </linearGradient>
      <linearGradient id="colorView2" x1="0" y1="0" x2="0" y2="1">
        <stop offset="30%" stopColor="#ec1c1c" stopOpacity={0.4} />
        <stop offset="75%" stopColor="#ec1c1c6b" />
        <stop offset="95%" stopColor="#ec1c1c40" />
      </linearGradient>
    </Fragment>
  );
};

const MyGraph = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data}>
          <defs>
            <GradientColors />
          </defs>
          <Tooltip
            itemStyle={{ color: "#fff", backgroundColor: "#0A1322" }}
            contentStyle={{ backgroundColor: "#0A1322" }}
          />
          <CartesianGrid
            strokeDasharray="none"
            vertical={false}
            stroke="#fff"
            opacity={0.4}
          />
          <XAxis
            dataKey="name"                   // data key from demo data
            tick={{ fill: "#B6BAC3" }}
            stroke="#fff"
            opacity={0.4}
            tickLine={false}
            dy={15}
          />
          <YAxis
            tick={{ fill: "#B6BAC3" }}
            stroke="#fff"
            opacity={0.4}
            tickLine={false}
            dx={-15}
            ticks={[0, 20, 40, 60, 80, 100, 120]}
          />
          <Area
            dataKey="events"                  // data key from demo data
            type="monotone"
            stroke="#ec991c"
            strokeWidth={3}
            strokeOpacity={1}
            fill="url(#colorView)"   // Id from GradientColors component
          />
          <Area
            dataKey="offers"                  // data key from demo data
            type="monotone"
            stroke="#ec1c1c"
            strokeWidth={3}
            strokeOpacity={1}
            fill="url(#colorView)"   // Id from GradientColors component
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
export default MyGraph;