import AdminRoutes from "./Admin.routes";
import { get, post, put, patch, deleted } from "../index";

const AdminServices = {
  getAllMembers: async (
    page, limit, is_approved, member_id, type, created_at_from, created_at_to, city_id, search
  ) => {
    let param = `?page=${page}&limit=${limit}`;
    if (is_approved != "") {
      param += `&is_approved=${is_approved}`;
    }
    if (member_id != "") {
      param += `&member_id=${member_id}`;
    }
    if (type != "") {
      param += `&type=${type}`;
    }
    if (created_at_from != "") {
      param += `&created_at_from=${created_at_from}`;
    }
    if (created_at_to != "") {
      param += `&created_at_to=${created_at_to}`;
    }
    if (city_id != "") {
      param += `&city_id=${city_id}`;
    }
    if (search != "") {
      param += `&search=${search}`;
    }
    const result = await get(AdminRoutes.getAllMembers + param);
    return result;
  },

  getMemberById: async (id) => {
    const result = await get(AdminRoutes.getMemberById + `?member_id=${id}`);
    return result;
  },

  getAllBusiness: async (
    page, limit, is_approved, business_id, created_at_from, created_at_to, company_name, search
  ) => {
    let params = `?page=${page}&limit=${limit}`
    if (is_approved != "" || is_approved != undefined) {
      params += `&is_approved=${is_approved}`
    }
    if (business_id != "") {
      params += `&business_id=${business_id}`
    }
    if (created_at_from != "") {
      params += `&created_at_from=${created_at_from}`
    }
    if (created_at_to != "") {
      params += `&created_at_to=${created_at_to}`
    }
    if (company_name != "") {
      params += `&company_name=${company_name}`
    }
    if (search != "") {
      params += `&search=${search}`
    }
    const result = await get(AdminRoutes.getAllBusiness + params);
    return result;
  },

  getAllBrands: async (id) => {
    let params = `?business_id=${id}`
    const result = await get(AdminRoutes.getAllBrands + params);
    return result;
  },

  getBusinessById: async (id) => {
    const result = await get(AdminRoutes.getBusinessById + `?business_id=${id}`);
    return result;
  },

  getAllOffers: async (
    page, limit, is_approved, offer_id, created_at_from, created_at_to, offer_name, city_id, search
  ) => {
    let params = `?page=${page}&limit=${limit}`
    if (is_approved != "") {
      params += `&is_approved=${is_approved}`
    }
    if (offer_id != "") {
      params += `&offer_id=${offer_id}`
    }
    if (created_at_from != "") {
      params += `&created_at_from=${created_at_from}`
    }
    if (created_at_to != "") {
      params += `&created_at_to=${created_at_to}`
    }
    if (offer_name != "") {
      params += `&offer_name=${offer_name}`
    }
    if (city_id != "") {
      params += `&city_id=${city_id}`
    }
    if (search != "") {
      params += `&search=${search}`
    }
    const result = await get(AdminRoutes.getAllOffers + params);
    return result;
  },

  getOfferDetails: async (id) => {
    const result = await get(AdminRoutes.getOfferDetails + `?offer_id=${id}`);
    return result;
  },

  getAllInvites: async (page, limit) => {
    const result = await get(AdminRoutes.getAllInvites + `?page=${page}&limit=${limit}`);
    return result;
  },

  getAllEvents: async (
    page, limit, is_approved, event_id, created_at_from, created_at_to, name, city_id, search
  ) => {
    let params = `?page=${page}&limit=${limit}`
    if (is_approved != "") {
      params += `&is_approved=${is_approved}`
    }
    if (event_id != "") {
      params += `&event_id=${event_id}`
    }
    if (created_at_from != "") {
      params += `&created_at_from=${created_at_from}`
    }
    if (created_at_to != "") {
      params += `&created_at_to=${created_at_to}`
    }
    if (name != "") {
      params += `&name=${name}`
    }
    if (city_id != "") {
      params += `&city_id=${city_id}`
    }
    if (search != "") {
      params += `&search=${search}`
    }
    const result = await get(AdminRoutes.getAllEvents + params);
    return result;
  },

  getEventDetails: async (event_id) => {
    const result = await get(AdminRoutes.getEventDetails + `?event_id=${event_id}`);
    return result;
  },

  updateStatus: async (obj) => {
    const result = await put(AdminRoutes.updateStatus, obj);
    return result;
  },

  approveEvent: async (obj) => {
    const result = await put(AdminRoutes.approveEvent, obj);
    return result;
  },

  approveOffer: async (obj) => {
    const result = await put(AdminRoutes.approveOffer, obj);
    return result;
  },

  sendInvite: async (obj, types) => {
    const result = await post(AdminRoutes.sendInvite + `?type=${types.join(",")}`, obj);
    return result;
  },

  getInvitedMembers: async (page, limit, id) => {
    const result = await get(AdminRoutes.getInvitedMembers + `/${id}` + `?status=accepted&page=${page}&limit=${limit}`);
    return result;
  },

  login: async (obj) => {
    const result = await post(AdminRoutes.login, obj);
    return result;
  },

  createCategory: async (obj) => {
    const result = await post(AdminRoutes.createCategory, obj);
    return result;
  },

  getAllCategories: async (page, limit, search) => {
    let params = `?page=${page}&limit=${limit}`;
    if (search != "") {
      params += `&search=${search}`;
    }
    const result = await get(AdminRoutes.getAllCategories + params);
    return result;
  },

  updateCategory: async (id, obj) => {
    const result = await put(AdminRoutes.updateCategory + `/${id}`, obj);
    return result;
  },

  deleteCategory: async (id) => {
    const result = await deleted(AdminRoutes.deleteCategory + `/${id}`);
    return result;
  },

  getStats: async () => {
    const result = await get(AdminRoutes.getStats);
    return result;
  },

  updateUser: async (id, obj) => {
    const result = await put(AdminRoutes.updateUser + `/${id}`, obj);
    return result;
  },

  getNotifications: async () => {
    const result = await get(AdminRoutes.getNotifications);
    return result;
  },

  getNotificationsCount: async () => {
    const result = await get(AdminRoutes.getNotificationsCount);
    return result;
  },

  sendNotifications: async (obj) => {
    const result = await post(AdminRoutes.sendNotifications, obj);
    return result;
  },

  getCustomNotifications: async (
    page,
    limit,
    cust_noti_id,
    title,
    created_at_from,
    created_at_to
  ) => {
    let params = `?page=${page}&limit=${limit}`;
    if (cust_noti_id != "") {
      params += `&cust_noti_id=${cust_noti_id}`;
    };
    if (title != "") {
      params += `&title=${title}`;
    };
    if (created_at_from != "") {
      params += `&created_at_from=${created_at_from}`;
    };
    if (created_at_to != "") {
      params += `&created_at_to=${created_at_to}`;
    };
    const result = await get(AdminRoutes.getCustomNotifications + params);
    return result;
  },

  uploadImage: async (obj) => {
    const result = await post(AdminRoutes.uploadImage, obj);
    return result;
  },

  updateImages: async (obj) => {
    const result = await put(AdminRoutes.updateImages, obj);
    return result;
  },

  getImages: async () => {
    const result = await get(AdminRoutes.getImages);
    return result;
  },

  getCountries: async () => {
    const result = await get(AdminRoutes.getCountries);
    return result;
  },

  getCities: async (id) => {
    const result = await get(AdminRoutes.getCities + `/${id}`);
    return result;
  },

  createSubAdmin: async (obj) => {
    const result = await post(AdminRoutes.createSubAdmin, obj);
    return result;
  },

  getSubAdmins: async (page, limit, city_id, search) => {
    let params = `?page=${page}&limit=${limit}`;
    if (city_id != "") {
      params += `&city_id=${city_id}`;
    };
    if (search != "") {
      params += `&search=${search}`;
    };
    const result = await get(AdminRoutes.getSubAdmins + params);
    return result;
  },

  updateSubAdmin: async (id, obj) => {
    const result = await put(AdminRoutes.updateSubAdmin + `/${id}`, obj);
    return result;
  },

  startSubscription: async (user_id) => {
    const result = await post(AdminRoutes.startSubscription + `?user_id=${user_id}`);
    return result;
  },

  approveSubscription: async (obj) => {
    const result = await post(AdminRoutes.approveSubscription, obj);
    return result;
  },

  getMembershipDetails: async (id) => {
    const result = await get(AdminRoutes.getMembershipDetails + `?user_id=${id}`);
    return result;
  },

  getAllMemberships: async () => {
    const result = await get(AdminRoutes.getAllMemberships);
    return result;
  },

  getAvailedOffers: async (id) => {
    const result = await get(AdminRoutes.getAvailedOffers + `/${id}`);
    return result;
  },

  updateEvent: async (obj) => {
    const result = await patch(AdminRoutes.updateEvent, obj);
    return result;
  },

  updateOffer: async (obj) => {
    const result = await patch(AdminRoutes.updateOffer, obj);
    return result;
  },

  updateMember: async (id, obj) => {
    const result = await put(AdminRoutes.updateMember + `/${id}`, obj);
    return result;
  },

  updateBrand: async (id, obj) => {
    const result = await put(AdminRoutes.updateBrand + `/${id}`, obj);
    return result;
  },

  updateBranch: async (id, obj) => {
    const result = await put(AdminRoutes.updateBranch + `/${id}`, obj);
    return result;
  }
};

export default AdminServices;