import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Grid, Typography, TextField, CardMedia, IconButton } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { East, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import Colors from '../../style';
import Images from '../../assets/images';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { convertFieldResponseIntoMuiTextFieldProps } from '@mui/x-date-pickers/internals';
import AdminServices from '../../api/admin/AdminServices';
import { useNavigate } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from '../../components/toaster';
import useAuth from '../../hooks/useAuth';
import { getUserToken } from '../../../firebase';

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: "15px",
      borderColor: Colors.white, // Remove the default border
    },
    '&:hover fieldset': {
      borderColor: Colors.white, // Remove the border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary, // Remove the border on focus
      borderWidth: "1px",
      color: Colors.primary
    },
    color: Colors.white,
  },
  '& .MuiInputBase-input': {
    color: Colors.white,
    fontSize: '14px',
  },
}))

function Login() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate()
  const { userLogin } = useAuth();

  const handleVisibility = () => {
    setVisible(!visible)
  }

  const getPermission = async (formData) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission == 'granted') {
        localStorage.setItem('Permission', 'granted');
      } else {
        localStorage.setItem('Permission', 'blocked');
      }
    } catch (error) {
      console.error('Error while requesting notification permission:', error);
    }
  };

  const handleLogin = async (formData) => {
    try {
      setLoading(true);
      getPermission();
      const fcm_token = await getUserToken();
      const obj = {
        email: formData.email,
        password: formData.password,
        fcm_token: fcm_token
      }
      const permission = localStorage.getItem("Permission");
      if (permission == "granted") {
        const result = await AdminServices.login(obj);
        if (result.responseCode == 200) {
          userLogin(result?.data);
          navigate("/");
        };
      } else if (permission == "blocked") {
        ErrorToaster('Please enable notification permission');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          left: "20%",
          top: "0%",
          background: Colors.secondary,
          opacity: 0.2,
          width: "50%",
          height: "25%",
          borderRadius: "50%",
          filter: "blur(150px)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: "-20%",
          bottom: "0%",
          background: Colors.secondary,
          opacity: 0.2,
          width: "50%",
          height: "25%",
          borderRadius: "50%",
          filter: "blur(150px)",
        }}
      />
      <Grid container justifyContent={"center"}>
        <Grid item md={6}>
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              background: Colors.dark1,
              px: "100px"
            }}
          >
            <Container>
              <Grid
                onSubmit={handleSubmit(handleLogin)}
                container
                // height={"100%"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                rowGap={3}
                component={"form"}
              >
                <Grid item md={12}>
                  <Box
                    sx={{ pb: 6 }}
                  >
                    <CardMedia
                      component={"img"}
                      src={Images.logo}
                      sx={{
                        width: "100%",
                        objectFit: "contain"
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={12} width={"100%"}>
                  <Box
                    sx={{
                      pb: 6
                    }}
                  >
                    <Typography
                      className={"banner-text"}
                      sx={{
                        fontWeight: 700,
                        fontFamily: "sora",
                        fontSize: "24px",
                        textAlign: "center",
                        color: Colors.white,
                      }}
                    >
                      Welcome to Fanoos!
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: Colors.white,
                        opacity: 0.5
                      }}
                    >
                      Fill the following details to sign in
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} width={"100%"}>
                  <InputField
                    fullWidth={true}
                    placeholder={"Email Address"}
                    type={"email"}
                    {...register("email")}
                  />
                </Grid>
                <Grid item md={12} width={"100%"}>
                  <InputField
                    fullWidth={true}
                    placeholder={"Password"}
                    type={visible ? "text" : "password"}
                    {...register("password")}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleVisibility}
                        >
                          {visible ? <VisibilityOutlined sx={{ color: Colors.primary }} />
                            : <VisibilityOffOutlined sx={{ color: Colors.primary }} />}
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item md={12} width={"100%"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <PrimaryButton
                      fullWidth={true}
                      loading={loading}
                      type={"submit"}
                      title={"Sign In"}
                      buttonStyle={{
                        color: Colors.white,
                        p: "16px 15px"
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              backgroundImage: `url(${Images.login})`,
              height: "100%",
              width: "100%",
              backgroundPosition: "center"
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login