import React, { Fragment, useEffect, useState } from 'react';
import { Box, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import Colors from '../../../style';
import BreadCrumb from '../../../components/breadcrumb';
import { InputSelectField } from '../../../components/inputfields';
import { PrimaryButton, ToggleButton } from '../../../components/buttons';
import { useForm } from 'react-hook-form';
import "@fontsource/nunito-sans"
import AdminServices from '../../../api/admin/AdminServices';
import { VisibilityOffOutlined, VisibilityOutlined, Upload } from '@mui/icons-material';
import Images from '../../../assets/images';
import { baseUrl } from '../../../../axios';
import { SuccessToaster } from '../../../components/toaster';
import { useLocation, useNavigate } from 'react-router-dom';

function UpdateAdmin() {
  const { register, handleSubmit, formState: { errors }, reset, getValues, watch, trigger, setValue } = useForm();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [image, setImage] = useState("");
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  const getCountries = async () => {
    try {
      const result = await AdminServices.getCountries();
      if (result.responseCode == 200) {
        setCountries(result.data.countries);
        const defaultCountry = result.data.countries.find((item) => item.name == state?.city.country_name);
        setSelectedCountry(defaultCountry);
        getCities(defaultCountry?.country_id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCities = async (id) => {
    try {
      const result = await AdminServices.getCities(id);
      if (result.responseCode == 200) {
        setCities(result.data.cities);
        const defaultCity = result.data.cities.find((item) => item.name == state?.city?.name);
        setSelectedCity(defaultCity)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("document", file);
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        setImage(result.data.files[0].path);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitForm = async (formData) => {
    setLoading(true);
    const obj = {
      first_name: formData.name,
      phone: formData.phone,
      email: formData.email,
      picture: image,
      city_id: selectedCity?._id || formData.city._id,
      is_active: isActive
    };
    try {
      const result = await AdminServices.updateSubAdmin(state?._id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      reset();
      setImage("");
      setSelectedCountry(null);
      setSelectedCity(null);
      navigate("/admin-list");
    }
  };

  const setDefaultValues = () => {
    setIsActive(state?.is_active)
    setImage(state?.picture);
    setValue("name", state?.first_name);
    setValue("phone", state?.phone);
    setValue("email", state?.email);
    setValue("country", selectedCountry);
    setValue("city", selectedCity);
  }

  // watch("password");

  useEffect(() => {
    getCountries();
    setDefaultValues();
  }, []);

  return (
    <Fragment>
      <Grid container justifyContent={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Update Admin"} />
        </Grid>
        <Grid item md={10} mt={2}>
          <Grid
            component={"form"}
            container
            rowGap={2}
            sx={{
              background: Colors.dark1,
              borderRadius: "12px",
              p: 3,
              boxShadow: `0px 0px 20px 0px ${Colors.shadowColor + "B3"}`,
            }}
            onSubmit={handleSubmit(submitForm)}
          >
            <Grid item md={12}>
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                      }}
                    >
                      <Box
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        position="relative"
                        display="inline-block"
                      >
                        <CardMedia
                          component="img"
                          image={image == "" ? Images.defaultUser : baseUrl + image}
                          alt="Default Image"
                          sx={{ width: '75px', height: '75px', borderRadius: "50%" }}
                        />
                        {hover && (
                          <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="rgba(0, 0, 0, 0.5)"
                            borderRadius={"50%"}
                          >
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                uploadImage(file);
                              }}
                            />
                            <label htmlFor="icon-button-file">
                              <IconButton color="primary" aria-label="upload picture" component="span">
                                <Upload style={{ color: 'white', fontSize: '2rem' }} />
                              </IconButton>
                            </label>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center"
                        }}
                      >
                        <Typography sx={{ color: Colors.white, fontFamily: "Nunito Sans", fontSize: "20px" }}>
                          Profile Image
                        </Typography>
                        <Typography sx={{ color: Colors.white, fontFamily: "Nunito Sans", }} variant='caption'>(Upload png or jpeg only)</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        width: "120px"
                      }}
                    >
                      <Typography
                        sx={{ fontFamily: "Nunito Sans", color: Colors.white }}
                      >
                        Status:
                      </Typography>
                      <ToggleButton
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                        label={isActive ? "Active" : "Inactive"}
                        width={"70px"}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Name"}
                    register={register("name", {
                      required: "Name"
                    })}
                    error={errors?.name && true}
                    helperText={errors?.name?.message}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Phone"}
                    register={register("phone")}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputSelectField
                    fullWidth={true}
                    label={"Email"}
                    register={register("email", {
                      required: "Email"
                    })}
                    error={errors?.email && true}
                    helperText={errors?.email?.message}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                {/* <Grid item md={6}>
                  <InputSelectField
                    disabled={true}
                    fullWidth={true}
                    label={"Password"}
                    type={visiblePassword ? "text" : "password"}
                    register={register("password", {
                      required: "Password"
                    })}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          disabled
                          sx={{
                            color: Colors.primary,
                            "&.Mui-disabled": {
                              "svg": {
                                color: `${Colors.white + 30} !important`
                              }
                            }
                          }}
                          onClick={() => setVisiblePassword(!visiblePassword)}
                        >
                          {visiblePassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    disabled={true}
                    fullWidth={true}
                    label={"Confirm Password"}
                    type={visibleConfirmPassword ? "text" : "password"}
                    register={register("cnfpassword", {
                      required: "Confirm Password",
                      validate: (value) => {
                        const password = getValues("password");
                        return value === password || "Passwords do not match";
                      },
                      onChange: () => {
                        trigger("cnfpassword"); // Re-validate confirm password field on change
                      }
                    })}
                    error={!!errors?.cnfpassword}
                    helperText={errors?.cnfpassword?.message}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          disabled
                          sx={{
                            color: Colors.primary,
                            "&.Mui-disabled": {
                              "svg": {
                                color: `${Colors.white + 30} !important`
                              }
                            }
                          }}
                          onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
                        >
                          {visibleConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                      )
                    }}
                  />
                </Grid> */}
                <Grid item md={6}>
                  <InputSelectField
                    select={true}
                    data={countries}
                    fullWidth={true}
                    label={"Country"}
                    value={selectedCountry}
                    register={register("country", {
                      value: selectedCountry,
                      required: selectedCountry == null ? "Country" : false,
                      onChange: (e) => {
                        const id = e.target.value.country_id;
                        setSelectedCountry(e.target.value)
                        getCities(id);
                      },
                    })}
                    error={errors?.country && true}
                    helperText={errors?.country?.message}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputSelectField
                    fullWidth={true}
                    select={true}
                    data={cities}
                    label={"City"}
                    value={selectedCity}
                    register={register("city", {
                      value: selectedCity,
                      required: selectedCity == null ? "City" : false,
                      onChange: (e) => {
                        setSelectedCity(e.target.value)
                      }
                    })}
                    error={errors?.city && true}
                    helperText={errors?.city?.message}
                  />
                </Grid>
                <Grid item md={12}>
                  <PrimaryButton
                    fullWidth
                    loading={loading}
                    title={"Submit"}
                    type={"submit"}
                    buttonStyle={{ p: "14px 24px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default UpdateAdmin