import React, { useState, useEffect, Fragment } from 'react';
import { Autocomplete, TextField, CircularProgress, Dialog, IconButton, DialogTitle, Box, Grid } from '@mui/material';
import usePlacesAutocomplete from "use-places-autocomplete";
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { setKey, setRegion, fromLatLng, fromAddress } from "react-geocode";
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import Colors from '../../style';
import { PrimaryButton } from '../buttons';
import { ErrorToaster } from '../toaster';

const googleMapKey = process.env.REACT_APP_MAP_API_KEY;

const PlacesAutocomplete = ({ address, geoAddress, setAddress }) => {
  const {
    setValue,
    suggestions: { data },
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "pk",
      componentRestrictions: { country: "pk" },
    },
  });

  return (
    <Autocomplete
      sx={{ my: 2 }}
      size="small"
      fullWidth
      id="combo-box-demo"
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        geoAddress(newValue);
        setAddress(newValue);
      }}
      defaultValue={address}
      value={address}
      options={data.map((option) => option.description)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Pin Location"
          sx={{
            "input": {
              color: Colors.white
            },
            ":hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: Colors.secondary
            },
            ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Colors.primary
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: Colors.primary
            }
          }}
          InputLabelProps={{
            sx: {
              "&.Mui-focused": {
                color: Colors.primary,
              },
              color: Colors.primary,
              ":hover": {
                color: Colors.secondary,
                borderColor: Colors.secondary
              }
            }
          }}
        />
      )}
    />
  );
};

function Map({ newAddress, defaultData, setAddressDetail }) {
  setKey(googleMapKey);
  setRegion("uae");

  // *For Map Positions
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });

  // *For Address
  const [address, setAddress] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapKey,
    libraries: ["places"],
  });

  const containerStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "10px",
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
  };

  const handleMapLoad = (map) => {
    map.addListener("click", (e) => {
      fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        (response) => {
          setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const handleMarkerLoad = (marker) => {
    marker.addListener("dragend", (e) => {
      fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        (response) => {
          setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const geoAddress = (address) => {
    console.log("Address before geocoding:", address);
    if (address === null) {
      return;
    } else {
      fromAddress(address).then((response) => {
        setCenter({
          lat: response.results[0]?.geometry?.location?.lat,
          lng: response.results[0]?.geometry?.location?.lng,
        });
        setMarkerPosition({
          lat: response.results[0]?.geometry?.location?.lat,
          lng: response.results[0]?.geometry?.location?.lng,
        });
      });
    }
  };

  useEffect(() => {
    if (defaultData) {
      setCenter({ lat: defaultData?.latitude, lng: defaultData?.longitude });
      setMarkerPosition({
        lat: defaultData?.latitude,
        lng: defaultData?.longitude,
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
        setMarkerPosition({ lat: latitude, lng: longitude });
      });
    }
  }, []);

  useEffect(() => {
    if (address) {
      let obj = {
        address: address,
        latitude: center?.lat,
        longitude: center?.lng,
      };
      newAddress(obj);
      setAddressDetail(obj);
    }
  }, [address]);

  if (!isLoaded) return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress sx={{ color: Colors.secondary }} />
    </Box>
  );

  return (isLoaded &&
    <Fragment>
      <PlacesAutocomplete
        setAddress={setAddress}
        address={address}
        geoAddress={geoAddress}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        options={options}
        onLoad={handleMapLoad}
      >
        <MarkerF
          position={markerPosition}
          draggable={true}
          onDragEnd={(e) => {
            setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
          onLoad={handleMarkerLoad}
        />
      </GoogleMap>
    </Fragment>
  );
}

function AddressForm({ open, onClose, defaultData, save }) {
  const {
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  // *For Address Detail
  const [addressDetail, setAddressDetail] = useState("");

  const submitForm = () => {
    try {
      let obj = {
        ...addressDetail,
      };
      save(obj);
      reset();
      onClose();
    } catch (error) {
      ErrorToaster(error?.message);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 1.5, md: 3 },
          px: { xs: 1, md: 3 },
          background: Colors.dark1
        },
      }}
    >
      <IconButton
        color="primary"
        onClick={() => {
          onClose();
          reset();
        }}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close sx={{ color: Colors.secondary }} />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700, color: Colors.white }}
      >
        {"Select Location"}
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} sm={12}>
            <Map
              newAddress={(data) => {
                setValue("address", data?.address);
              }}
              defaultData={defaultData}
              setAddressDetail={setAddressDetail}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <PrimaryButton
              fullWidth
              type={"submit"}
              title={"Save"}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default AddressForm