import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import Colors from '../../../style';
import BreadCrumb from '../../../components/breadcrumb';
import { PageDot } from '../../../components/UI';
import { MemberListIcon } from '../../../assets/icons';
import { CloseRounded, DeleteOutline, DeleteOutlineOutlined, EditOutlined, KeyboardArrowLeft, KeyboardArrowRight, PlaylistAddOutlined, SaveAlt, Search } from '@mui/icons-material';
import { DateField, InputField, InputSelectField } from '../../../components/inputfields';
import { PrimaryButton, ToggleButton } from '../../../components/buttons';
import AdminServices from '../../../api/admin/AdminServices';
import { SuccessToaster } from '../../../components/toaster';
import moment from 'moment';
import CategoryDialog from '../../../components/dialog';
import { ConfirmationDialog } from '../../../components/dialog';
import Filter from "../../../components/filter";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

const tableHead = [
  "S.No",
  "Name",
  "Name In Arabic",
  "Created At",
  "Type",
  "Status",
  "Action"
];

const types = ["Member", "Business", "Events & Offers"];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBack = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNext = (event) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        sx={{
          borderRadius: 2,
          background: Colors.dark1,
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={page == 0}
          aria-label="previous page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={{
            color: Colors.white,
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
};

function Settings() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(null);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const getAllCategories = async (page, limit, search) => {
    try {
      const result = await AdminServices.getAllCategories(
        page ? page : 1,
        limit ? limit : 10,
        search ? search : ""
      );
      if (result.responseCode == 200) {
        setCategories(result?.data?.categories)
        setCount(result?.data?.totalCategory);
        setDefaultStatus(result?.data?.categories);
      }
    } catch (err) {
      console.log(err)
    }
  };

  const handleCreate = async (formData, resetForm) => {
    setLoading(true);
    const obj = {
      name: formData.name,
      name_ar: formData.name_ar,
      type: formData.type
    }
    try {
      const result = await AdminServices.createCategory(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        resetForm();
        setOpen(false);
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
      getAllCategories();
    }
  };

  const handleUpdate = async (formData, resetForm) => {
    setLoading(true)
    const obj = {
      name: formData.name,
      name_ar: formData.name_ar,
      type: formData.type
    }
    try {
      const result = await AdminServices.updateCategory(data?._id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        resetForm();
        setUpdateOpen(false);
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
      getAllCategories();
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const result = await AdminServices.deleteCategory(data?._id);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setDeleteOpen(false);
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
      getAllCategories(page, limit, "");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllCategories(newPage + 1, limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = async (id) => {
    const index = isChecked.indexOf(id);
    const newStatus = [...isChecked];
    let checked;
    if (index == -1) {
      newStatus.push(id);
      checked = true;
    } else {
      newStatus.splice(index, 1);
      checked = false;
    }
    setIsChecked(newStatus);
    const obj = {
      isActive: checked
    };
    try {
      const result = await AdminServices.updateCategory(id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      };
    } catch (err) {
      console.log(err);
    } finally {
      getAllCategories();
    }
  };

  const setDefaultStatus = (data) => {
    try {
      let status = [];
      data.forEach(element => {
        if (element.isActive) {
          status.push(element._id);
        }
      });
      setIsChecked(status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setSelectedName("");
    setSelectedType("");
    getAllCategories();
  };

  const handleDownload = () => {
    const head = tableHead.filter((item) => item.name != "Action").map((item) => item.name);
    const data = categories.map((item, ind) => [
      ind + 1,
      item.name,
      item.name_ar,
      item.createdAt,
      item.type,
      item.isActive ? "Active" : "Inactive"
    ]);

    const ws = XLSX.utils.aoa_to_sheet([head, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the file using FileSaver.js
    saveAs(new Blob([buf]), "categories.xlsx");
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <Fragment>
      <CategoryDialog
        loading={loading}
        title={"Create Category"}
        open={open}
        onClose={() => setOpen(false)}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        onSubmit={(data, reset) => handleCreate(data, reset)}
      />

      <CategoryDialog
        loading={loading}
        title={"Update Category"}
        open={updateOpen}
        data={data}
        onClose={() => setUpdateOpen(false)}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        onSubmit={(data, reset) => handleUpdate(data, reset)}
      />

      <ConfirmationDialog
        loading={loading}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onClick={handleDelete}
        title={"Delete Category"}
        desc={"You won't be able to revert this!"}
        prmBtnTitle={"Delete"}
        secBtnTitle={"Cancel"}
      />

      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb pageName={"Categories"} />
        </Grid>
        <Grid item md={10}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Filter
              onClick={handleReset}
              items={
                [
                  <Grid item md={5.25}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        value={selectedName}
                        fullWidth={true}
                        placeholder={"Name"}
                        type={"text"}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value != "") {
                            setSelectedName(e.target.value);
                          } else {
                            setSelectedName("");
                          }
                        }}
                      />
                    </Box>
                  </Grid>,

                  <Grid item md={5.25}
                    sx={{
                      borderRight: `1px solid ${Colors.dark2}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%"
                      }}
                    >
                      <InputField
                        select={true}
                        value={selectedType}
                        fullWidth={true}
                        placeholder={"Type"}
                        options={types}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value !== "") {
                            setSelectedType(value);
                          }
                        }}
                      />
                    </Box>
                  </Grid>,
                ]
              }
            />
          </Box>
        </Grid>
        <Grid item md={1.5}>
          <Box
            sx={{ p: 2 }}
          >
            <PrimaryButton
              title={"Search"}
              onClick={() => getAllCategories(
                "",
                "",
                selectedName != "" ? selectedName : selectedType != "" ? selectedType : ""
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: "16px 16px 0px 16px"
        }}
      >
        <Box
          sx={{
            background: Colors.dark1,
            p: "16px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px",
            height: "calc(100vh - 317px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative"
            }}
          >
            <PageDot top={"13px"} left={"-24px"} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <MemberListIcon />
              <Typography sx={{ color: Colors.white }}>
                Categories
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1
              }}
            >
              <IconButton
                sx={{
                  background: Colors.buttonGradient
                }}
                onClick={() => handleDownload()}
              >
                <SaveAlt sx={{ color: Colors.white }} />
              </IconButton>
              <IconButton
                onClick={() => setOpen(true)}
                sx={{
                  background: Colors.buttonGradient
                }}
              >
                <PlaylistAddOutlined sx={{ color: Colors.white }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: Colors.dark3
                }}
              >
                <TableRow>
                  {tableHead.map((item, ind) => (
                    <TableCell
                      key={ind}
                      sx={{
                        p: "10px 16px",
                        color: Colors.white,
                        borderBottom: "none",
                        borderRadius: ind == 0
                          ? "20px 0 0 20px"
                          : ind == tableHead.length - 1
                            ? "0 20px 20px 0"
                            : "none",
                        textAlign: "center"
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((item, ind) => (
                  <TableRow key={ind}>
                    <TableCell
                      sx={{
                        color: Colors.white,
                        p: "8px 16px",
                        textAlign: "center"
                      }}
                    >
                      {ind + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: Colors.white,
                        p: "8px 16px",
                        textAlign: "center"
                      }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: Colors.white,
                        p: "8px 16px",
                        textAlign: "center"
                      }}
                    >
                      {item.name_ar ?? "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: Colors.white,
                        p: "8px 16px",
                        textAlign: "center"
                      }}
                    >
                      {moment(item.createdAt).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: Colors.white,
                        p: "8px 16px",
                        textAlign: "center"
                      }}
                    >
                      {item.type ?? "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "8px 16px",
                        textAlign: "center"
                      }}
                    >
                      <ToggleButton
                        checked={isChecked.indexOf(item?._id) !== -1}
                        onChange={() => {
                          handleStatusChange(item._id);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "8px 16px"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <IconButton
                          sx={{
                            background: Colors.buttonGradient,
                            color: Colors.white
                          }}
                          onClick={() => {
                            setUpdateOpen(true);
                            setData(item);
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                        {/* <IconButton
                          sx={{
                            background: Colors.buttonGradient,
                            color: Colors.white
                          }}
                          onClick={() => {
                            setDeleteOpen(true);
                            setData(item);
                          }}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton> */}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Table>
        <TablePagination
          sx={{
            borderBottom: "none",
            borderRadius: "12px",
            color: Colors.white,
            ".MuiTablePagination-spacer": {
              flex: 1,
            },
            ".MuiToolbar-root": {
              gap: "12px",
              paddingRight: "24px"
            }
          }}
          // rowsPerPageOptions={[10, 20, 30]}
          colSpan={12}
          count={count}
          rowsPerPage={limit}
          page={page}
          labelRowsPerPage={"Showing"}
          slotProps={{
            select: {
              sx: {
                display: "none"
              }
            }
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Table>
    </Fragment>
  )
}

export default Settings